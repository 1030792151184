import Activities from '../pages/Activities';
import ContactUs from '../pages/Profile/ContactUs';
import { Profile } from '../pages/Profile/Profile';
import HomePage from '../pages/HomePage';
import Trips from '../pages/Trips';
import { Landing } from '../pages/auth/Landing';
import ProfileCompleted from '../pages/auth/ProfileCompleted';
import { SplashScreen } from '../pages/auth/SplashScreen';
import Otp from '../pages/auth/RegisterOtp';
import { Routes as BrowserRoutes, Route } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import AuthGuard from './AuthGuard';
import { Wallet } from '../pages/Wallet';
import { EarnigsBreakdown } from '../pages/EarningsBreakdown';
import Messages from '../pages/Messages';
import Payouts from '../pages/Payouts';
import Preferences from '../pages/Preferences';
import MedicalInfo from '../pages/Profile/MedicalInformation/MedicalInfo';
import AppAppearance from '../pages/Profile/AppAppearance';
import PrivacyPolicy from '../pages/Profile/PrivacyPolicy';
import Terms from '../pages/Profile/Terms';
import Refund from '../pages/Refund';
import Faq from '../pages/FAQs/index';
import F1 from '../pages/FAQs/F1';
import F2 from '../pages/FAQs/F2';
import F3 from '../pages/FAQs/F3';
import F4 from '../pages/FAQs/F4';
import F5 from '../pages/FAQs/F5';
import F6 from '../pages/FAQs/F6';
import F7 from '../pages/FAQs/F7';
import F8 from '../pages/FAQs/F8';
import F9 from '../pages/FAQs/F9';
import F10 from '../pages/FAQs/F10';
import AboutUs from '../pages/AboutUs';
import ReportBug from '../pages/ReportBug';
import Register from '../pages/auth/Register';
import CompleteRegistration from '../pages/auth/CompleteRegistration';
import Login from '../pages/auth/Login';
import LoginOtp from '../pages/auth/LoginOtp';
import { HelpPage } from '../pages/HelpPage';
import EmailOtp from '../pages/auth/RegisterOtp';
import CompleteEmailReg from '../pages/auth/CompleteEmailReg';
import ProfileDetails from '../pages/Profile/ProfileDetails';
import SearchResult from '../pages/SearchResult';
import BookingDetails from '../pages/BookingDetails';
import Summary from '../pages/Summary';
import PaymentSuccess from '../shared/PaymentSuccess';
import { Cards } from '../pages/Cards';
import Booking from '../pages/Booking';
import PhoneLogin from '../pages/auth/PhoneLogin';
import PhoneOtp from '../pages/auth/PhoneOtp';
import RegPhoneOtp from '../pages/auth/RegisterPhoneOtp';
import RegisterPhone from '../pages/auth/RegisterPhone';
import Sook from '../pages/SookTest';

interface RouteConfig {
  path: string;
  element: JSX.Element;
  hasAuth?: boolean;
}

const ROUTES: RouteConfig[] = [
  {
    path: '/loader',
    element: <SplashScreen />,
  },
  {
    path: '/',
    element: localStorage.getItem('token') ? <HomePage /> : <Login />,
  },
  {
    path: '/landing',
    element: <Landing />,
  },
  {
    path: '/booking-details',
    element: <BookingDetails />,
  },
  {
    path: '/booking',
    element: <Booking />,
  },
  {
    path: '/booking/oneway',
    element: <Booking />,
  },
  {
    path: '/booking/roundtrip',
    element: <Booking />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/help',
    element: <HelpPage />,
  },
  {
    path: '/email-otp',
    element: <EmailOtp />,
  },
  {
    path: '/reg-phone-otp',
    element: <RegPhoneOtp />,
  },
  {
    path: '/login-otp',
    element: <LoginOtp />,
  },
  {
    path: '/phone-otp',
    element: <PhoneOtp />,
  },
  {
    path: '/phone-login',
    element: <PhoneLogin />,
  },
  {
    path: '/register-phone',
    element: <RegisterPhone />,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/complete-registration',
    element: <CompleteRegistration />,
  },
  {
    path: '/complete-email-reg',
    element: <CompleteEmailReg />,
  },
  {
    path: '/profile-completed',
    element: <ProfileCompleted />,
  },
  {
    path: '/home',
    element: <HomePage />,
    hasAuth: true,
  },
  {
    path: '/home/oneway',
    element: <HomePage />,
  },
  {
    path: '/home/roundtrip',
    element: <HomePage />,
  },
  {
    path: '/trips',
    element: <Trips />,
    hasAuth: true,
  },
  {
    path: '/refunds&policy',
    element: <Refund />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms&conditions',
    element: <Terms />,
  },

  {
    path: '/app-appearance',
    element: <AppAppearance />,
  },

  {
    path: '/wallet',
    element: <Wallet />,
    hasAuth: true,
  },
  {
    path: '/medical-info',
    element: <MedicalInfo />,
    hasAuth: true,
  },
  {
    path: '/medical-info/health',
    element: <MedicalInfo />,
  },
  {
    path: '/medical-info/emergency',
    element: <MedicalInfo />,
  },

  {
    path: '/activities',
    element: <Activities />,
    hasAuth: true,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/profile',
    element: <Profile />,
    hasAuth: true,
  },
  {
    path: '/profile-details',
    element: <ProfileDetails />,
    hasAuth: true,
  },
  {
    path: '/contact-us',
    element: <ContactUs />,
  },
  {
    path: '/earnings-breakdown',
    element: <EarnigsBreakdown />,
    hasAuth: true,
  },
  {
    path: '/activities/:id',
    element: <Messages />,
    hasAuth: true,
  },
  {
    path: '/payout',
    element: <Payouts />,
    hasAuth: true,
  },
  {
    path: '/preferences',
    element: <Preferences />,
    hasAuth: true,
  },
  {
    path: '/summary',
    element: <Summary />,
  },
  {
    path: '/search',
    element: <SearchResult />,
    hasAuth: true,
  },
  {
    path: '/sook',
    element: <Sook />,
    hasAuth: true,
  },
  {
    path: '/faq',
    element: <Faq />,
  },
  {
    path: '/faq-1',
    element: <F1 />,
  },
  {
    path: '/faq-2',
    element: <F2 />,
  },
  {
    path: '/faq-3',
    element: <F3 />,
  },
  {
    path: '/faq-4',
    element: <F4 />,
  },
  {
    path: '/faq-5',
    element: <F5 />,
  },
  {
    path: '/faq-6',
    element: <F6 />,
  },
  {
    path: '/faq-7',
    element: <F7 />,
  },
  {
    path: '/faq-8',
    element: <F8 />,
  },
  {
    path: '/faq-9',
    element: <F9 />,
  },
  {
    path: '/faq-10',
    element: <F10 />,
  },
  {
    path: '/report-bug',
    element: <ReportBug />,
  },
  {
    path: '/cards',
    element: <Cards />,
  },
];

const AppRoute = () => {
  return (
    <>
      <BrowserRoutes>
        {ROUTES.map((route) => {
          const { hasAuth } = route;
          const Parent = hasAuth ? AuthGuard : Fragment;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<Parent> {route.element} </Parent>}
            />
          );
        })}
      </BrowserRoutes>
    </>
  );
};

export default AppRoute;
