import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Card,
  IconButton,
} from '@mui/material';
import {
  ArrowBack,
  Apple,
  AccountCircle,
  Close,
  PhoneOutlined,
} from '@mui/icons-material';
import { ReactComponent as Google } from '../../assets/google.svg';
import move9jaLogo from '../../assets/move9ja.svg';
import backGroundImage from '../../assets/move9jaBoy.png';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useMutation } from '@tanstack/react-query';
import { sendOtp } from '../../services/auth';
import { handleMutationError } from '../../services/handler';

interface FormValues {
  email: string;
  type: string;
}

const schema = yup.object({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  type: yup.string().oneOf(['email']).required(),
});

const Register = () => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (data: FormValues) => {
      return await sendOtp({
        email: data.email,
        type: data.type,
      });
    },
    onSuccess: (data) => {
      console.log('on success', data);
      navigate('/email-otp', {
        state: { email: data?.data?.email, userId: data?.data?.user_id },
      });
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: 'email',
    },
  });

  const onSubmit = (data: FormValues) => {
    mutation.mutate(data);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box height='100vh' sx={{ position: 'fixed', width: '100%' }}>
      <Box
        height='72px'
        p='0px 80px'
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
        {/* <Button
          sx={{
            bgcolor: '#DA4733',
            width: '109px',
            borderRadius: '8px',
            fontWeight: 600,
            fontSize: '14px',
          }}
          variant='contained'
        >
          Register
        </Button> */}
      </Box>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          gridTemplateColumns: '40% 60%',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left center',
            height: 'calc(100vh - 70px)',
            width: '100%',
            margin: 0,
            padding: 0,
            display: { xs: 'none', md: 'grid' },
            placeItems: 'end',
            position: 'relative',
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display='flex'
          height={{ md: '100vh' }}
          alignItems='center'
          flexDirection='column'
        >
          <Box
            width={{ xs: '100%', md: '510px' }}
            p='0px 24px'
            mt={{ md: '70px', xs: '40px' }}
          >
            <Box display='flex' alignItems='center' gap={1} mb='12px'>
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={800} fontSize={20}>
                Register
              </Typography>
            </Box>
            <Typography mb={{ md: '42px', xs: '32px' }}>
              Let’s start with your Email
            </Typography>
            <form
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email
                </InputLabel>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Johndoe@mail.com'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: '#F9F9F9',
                        my: 2,
                        borderRadius: '10px',
                        borderStyle: 'none',
                        py: 1,
                        px: 2,
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                    />
                  )}
                />
              </FormControl>

              <Button
                disabled={!!mutation.isPending}
                type='submit'
                variant='contained'
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: '8px',
                  p: '10px 16px',
                }}
              >
                {mutation.isPending ? 'Loading...' : 'Continue'}
              </Button>
              {/* <Typography my='10px' textAlign='center'>
                OR
              </Typography>
              <Button
                sx={{
                  fontWeight: 600,
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  p: '10px 16px',
                  color: '#344054',
                }}
                fullWidth
                startIcon={<PhoneOutlined />}
                variant='outlined'
                onClick={() => navigate('/register-phone')}
              >
                Continue with Phone Number
              </Button>

              <Button
                sx={{
                  mt: '14px',
                  fontWeight: 400,
                  p: '10px 16px',
                  borderRadius: '8px',
                  bgcolor: 'black',
                }}
                fullWidth
                startIcon={<Apple />}
                variant='contained'
              >
                Continue with Apple
              </Button> */}
            </form>
            <Typography
              mt={{ md: 18, xs: '35%' }}
              textAlign='center'
              fontSize={14}
            >
              I have an account already?
              <span
                style={{
                  color: 'blue',
                  fontWeight: 600,
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={() => navigate('/login')}
              >
                Login
              </span>
            </Typography>
            <Box
              mt={{ md: 9, xs: 2 }}
              display='grid'
              sx={{ placeContent: 'center' }}
            >
              <Typography
                fontSize={12}
                textAlign='center'
                mx={{ md: 8, xs: 3 }}
                mb={3}
              >
                Creating an account means you have agree to our{' '}
                <a
                  target='_blank'
                  href='https://move9ja.com/terms-and-conditions/'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'inherit',
                  }}
                >
                  Terms{' '}
                </a>{' '}
                and
                <a
                  target='_blank'
                  href='https://move9ja.com/privacy-policy/'
                  rel='noreferrer'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'inherit',
                  }}
                >
                  {' '}
                  Privacy policy
                </a>
              </Typography>
              {/* <Box display='grid' sx={{ placeContent: 'center' }}>
                <Card
                  sx={{
                    display: 'flex',
                    gap: 2,

                    p: 1,
                    borderRadius: 3,
                    backgroundColor: '#255398',
                    width: { xs: '100%', md: '342px' },
                  }}
                  variant='elevation'
                >
                  <AccountCircle sx={{ color: 'white' }} />
                  <Box>
                    <Typography color='white' fontSize={14}>
                      Become a park agent in any of our registered parks in
                      Nigeria
                    </Typography>
                    <Typography color='white' fontWeight={200} fontSize={12}>
                      Get Started
                    </Typography>
                  </Box>
                  <Close sx={{ color: 'white' }} />
                </Card>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
