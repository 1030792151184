import {
  Box,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import move9jaLogo from "../../assets/move9ja.svg";
import backGroundImage from "../../assets/move9jaBoy.png";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { verifyOtp } from "../../services/auth";
import toast from "react-hot-toast";
import { handleMutationError } from "../../services/handler";

interface IFormInputs {
  otp: string;
}

const schema = yup
  .object({
    otp: yup.string().required("OTP is required"),
  })
  .required();

const PhoneOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Location", location?.state?.userId);
  }, [location]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data: IFormInputs) => {
      return await verifyOtp({
        user_id: location?.state?.userId,
        otp: data.otp,
        type: "phone",
      });
    },
    onSuccess: (data) => {
      console.log("Logged in data", data);
      toast.success("Logged in!A");
      localStorage.setItem("token", data?.data?.token);
      localStorage.setItem(
        "user_details",
        JSON.stringify(data?.data?.user_details)
      );
      navigate("/home");
    },
    onError: (error: unknown) => {
      handleMutationError(error);
      console.error("Error verifying OTP:", error);
    },
  });

  const onSubmit = (data: IFormInputs) => {
    mutation.mutate(data);
  };

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Login
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "grid" },
          gridTemplateColumns: { md: "40% 60%", xs: "100%" },
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: { xs: "none", md: "grid" },
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box p={2} mx={{ md: "20%", xs: 0 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
            }}
            mb={1}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={800} fontSize={20}>
              Login
            </Typography>
          </Box>
          <Box mx={2}>
            <Typography mb={4} fontWeight={500}>
              Enter the OTP sent to your phone
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  OTP code
                </InputLabel>
                <Controller
                  name="otp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Paste OTP"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      error={!!errors.otp}
                      helperText={errors.otp ? errors.otp.message : ""}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: 3,
                        outline: "none",
                        border: "none",
                      }}
                    />
                  )}
                />
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
                disabled={mutation.isPending}
              >
                {mutation.isPending ? "Verifying..." : "Continue"}
              </Button>
            </form>

            <Typography
              fontSize={12}
              fontWeight={500}
              textAlign="center"
              mt={1}
            >
              Resend OTP 0:59
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneOtp;
