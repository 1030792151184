import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Tick from './../assets/smallTick.svg';

export const HelpPage = () => {
  const navigate = useNavigate();
  return (
    <Box p={2}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Back
          </Typography>
        </Box>
        <Box mx={1}>
          <Typography mb={4} fontWeight={500}>
            Don't worry, we're here to help!
          </Typography>
          <Box display='flex' gap='12px' alignItems='center' mb={2}>
            <img
              src={Tick}
              alt='tick'
              style={{ height: '28px', width: '28px' }}
            />
            <Typography fontSize={16}>Checking caps lock status</Typography>
          </Box>
          <Box display='flex' gap='12px' alignItems='center' mb={2}>
            <img
              src={Tick}
              alt='tick'
              style={{ height: '28px', width: '28px' }}
            />
            <Typography fontSize={16}>
              Double-checking your email and password
            </Typography>
          </Box>
          <Box display='flex' gap='12px' alignItems='center' mb={2}>
            <img
              src={Tick}
              alt='tick'
              style={{ height: '28px', width: '28px' }}
            />
            <Typography fontSize={16}>
              Resetting your password via the "Forgot Password" link
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt='100%'>
        <Typography
          fontSize={12}
          fontWeight={500}
          textAlign='center'
          color='#475467'
          mt={1}
        >
          If issues persist, reach out to our support team: [support email]
          [support phone number]
        </Typography>
      </Box>
    </Box>
  );
};
