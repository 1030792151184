import http from './http';

import Compressor from 'compressorjs';

export const createBeneficiary = async (body: any) => {
  const res = http.post('/customer/create/createbeneficiary', body);
  return (await res).data;
};

export const uploadMedicationInfo = async (body: any) => {
  const res = http.post('/customer/profile/medical-information', body);
  return (await res).data;
};

export const uploadEmergencyContact = async (body: any) => {
  const res = http.post('/customer/profile/emergency-contact', body);
  return (await res).data;
};

export const getMedicationInfo = async () => {
  const res = http.get('/customer/profile/get-medical-information');
  return (await res).data;
};

export const getEmergencyContact = async () => {
  const res = http.get('/customer/profile/get-emergency-contact');
  return (await res).data;
};

export const sendDeleteAccoutOtp = async () => {
  const res = http.get('/customer/profile/delete-account-otp');
  return (await res).data;
};

export const deleteAccount = async (body: any) => {
  const res = http.post('/customer/profile/delete-account', body);
  return (await res).data;
};

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const compressImage = (file: any) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.3, // Adjust quality (0.6 is 60% of the original size)
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export const updatePhoto = async (file: any) => {
  // Compress the image
  const compressedFile: any = await compressImage(file);

  // Convert compressed file to base64
  const dt: any = await toBase64(compressedFile);

  // Send compressed base64 image
  const formData = { picture: dt };
  const res = await http.post(
    '/customer/profile/update-profile-picture/',
    formData
  );
  return res?.data;
};
