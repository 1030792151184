import { Box, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { keyframes } from '@emotion/react';
import { grey } from '@mui/material/colors';

const slideUpMobileView = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideFromRightResView = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface BottomDrawerProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  drawerHeight?: string;
  endText?: string | ReactNode;
  secondDesign?: boolean;
  secondDesignTitle?: string;
  defaultDesign?: boolean;
}

const BottomDrawer = ({
  children,
  open,
  onClose,
  drawerHeight = '90vh',
  endText,
  secondDesign,
  secondDesignTitle,
  defaultDesign,
}: BottomDrawerProps) => {
  const theme = useTheme();
  if (!open) return null;

  return (
    <>
      {/* Backdrop */}
      <Box
        position='fixed'
        top={0}
        left={0}
        width='100vw'
        height='100vh'
        bgcolor={'rgba(0, 0, 0, 0.5)'}
        zIndex={9}
        onClick={onClose} // Close the drawer when backdrop is clicked
      />

      {/* Drawer */}
      <Box
        height={{ xs: drawerHeight, md: '100vh' }}
        position='fixed'
        width={{ xs: '100vw', md: '36vw' }}
        bottom={0}
        right={{ md: 0 }}
        bgcolor={theme?.palette?.mode === 'dark' ? grey[900] : '#fff'}
        zIndex={10}
        borderRadius={{ xs: '12px 12px 0px 0px', md: '0px' }}
        sx={{
          animation: {
            xs: `${slideUpMobileView} 0.5s ease-out`,
            md: `${slideFromRightResView} 0.5s ease-out`,
          },
        }}
      >
        {defaultDesign && (
          <Box
            display={{ xs: 'flex', md: 'none' }}
            alignItems='center'
            justifyContent='space-between'
            borderBottom='0.5px solid #DADADA'
            p={1}
            sx={{
              animation: `${fadeIn} 0.5s ease-out 0.3s forwards`,
              opacity: 0, // Start with 0 opacity for the fade-in effect
            }}
          >
            <Typography
              display='flex'
              alignItems='center'
              fontWeight={600}
              fontSize='14px'
              onClick={onClose} // Handle go back action
              sx={{ cursor: 'pointer' }}
            >
              <ChevronLeftIcon />
              <span>Go back</span>
            </Typography>
            {endText || (
              <Typography
                fontSize='14px'
                color='#DA4733'
                onClick={onClose} // Handle cancel action
                sx={{ cursor: 'pointer' }}
              >
                Cancel
              </Typography>
            )}
          </Box>
        )}
        {secondDesign && (
          <Box>
            <Box
              display='flex'
              alignItems='center'
              flexDirection='column'
              justifyContent='center'
              p={2}
              border='1px solid #E4E7EC'
            >
              <Box
                sx={{
                  bgcolor: '#E4E7EC',
                  width: '50px',
                  height: '4px',
                  borderRadius: '100px',
                  mb: 1,
                }}
              ></Box>
              <Typography fontSize='16px' fontWeight={600}>
                {secondDesignTitle}
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            overflowY: 'scroll', // Use scroll to enable scrolling
            height: { md: '100vh', xs: 'calc(90vh - 40px)' },
            //maxHeight: 'calc(90vh - 40px)',
            pb: '40px',
            animation: `${fadeIn} 0.5s ease-out 0.8s forwards`,
            opacity: 0,
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar for webkit browsers
            },
            '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
            'scrollbar-width': 'none', // Hide scrollbar for Firefox
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default BottomDrawer;
