import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ReactComponent as SearchIcon } from './../assets/Search.svg';
import { ReactComponent as TripIcon } from './../assets/trips-icon.svg';
import { ReactComponent as WalletIcon } from './../assets/wallets-icon.svg';
import { ReactComponent as ActivityIcon } from './../assets/activities-icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Typography } from '@mui/material';
import useGetUser from '../hooks/useGetUser';
import { useQuery } from '@tanstack/react-query';
import { getUserProfile } from '../services/auth';
import AppLoader from '../components/Loader';

export default function SimpleBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading: isGetUserLoading } = useGetUser();
  const { data: tripDet, isLoading } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  const navigationItems = [
    {
      label: 'Home',
      icon: <SearchIcon />,
      route: '/home',
    },
    {
      label: 'Trips',
      icon: <TripIcon />,
      route: '/trips',
    },
    {
      label: 'Transactions',
      icon: <WalletIcon />,
      route: '/wallet',
    },
    {
      label: 'Activities',
      icon: <ActivityIcon />,
      route: '/activities',
    },
    {
      label: 'Accounts',
      icon: !tripDet?.data?.profileDetails?.image
        ?.toString()
        ?.includes('localhost') ? (
        <img
          src={tripDet?.data?.profileDetails?.image}
          alt='profile-img'
          width='30px'
          style={{ borderRadius: '50%' }}
        />
      ) : (
        <Avatar sx={{ height: '26px', width: '26px', fontSize: '12px', m: 0 }}>
          {data?.username[0]}
        </Avatar>
      ),
      route: '/profile',
    },
  ];

  if (isLoading || isGetUserLoading) {
    return <AppLoader />;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        display: { md: 'none', xs: 'block' },
      }}
    >
      <BottomNavigation
        showLabels
        value={location.pathname}
        sx={{
          '&.MuiBottomNavigation-root': {
            height: '88px',
            boxShadow:
              '0px 2px 4px -2px #1018280F, 0px -4px 8px -2px #1018281A',
          },
          '.MuiBottomNavigationAction-label': {
            mt: 1,
            fontSize: '0.6rem',
          },
          '.Mui-selected': {
            fontSize: '0.65rem !important',
            fontWeight: 700,
            color: 'primary.main', // Ensures text color changes to primary
          },
          '.Mui-selected .MuiBottomNavigationAction-iconOnly': {
            color: 'primary.main', // Ensures icon color changes to primary
          },
        }}
      >
        {navigationItems.map((item) => (
          <BottomNavigationAction
            key={item.route}
            onClick={() => navigate(item.route)}
            label={
              <Typography
                sx={{
                  mt: 1,
                  fontSize: '0.6rem',
                  fontWeight:
                    location.pathname === item.route ? '700' : 'inherit',
                  color:
                    location.pathname === item.route
                      ? 'primary.main'
                      : '#98A2B3',
                }}
              >
                {item.label}
              </Typography>
            }
            icon={item.icon}
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}
