import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './routes/AppRoute';
import { SearchProvider } from './Context/searchContext';
import { CustomThemeProvider } from './Context/themeContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { toastOption } from './config';
import { SocketProvider } from './Context/SocketProvider';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SearchProvider>
      <CustomThemeProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Toaster toastOptions={toastOption} />
            <SocketProvider>
              <AppRoute />
            </SocketProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </CustomThemeProvider>
    </SearchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
