import { ArrowBack } from '@mui/icons-material';
import {
  Typography,
  Box,
  IconButton,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReportPopUp from '../shared/ReportPopUp';
import BottomDrawer from '../shared/BottomDrawer';

const ReportBug = () => {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  return (
    <>
      <BottomDrawer
        drawerHeight='45vh'
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
      >
        <ReportPopUp />
      </BottomDrawer>
      <Box height='100vh' mx={{ md: '14%' }}>
        <Box p={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Report a bug or suggest a feature
            </Typography>
          </Box>
          <Box mx={1}>
            <Box
              display='grid'
              gridTemplateColumns='40% 60%'
              sx={{ placeItems: 'center', gap: '8px' }}
            >
              <Typography mb={1} fontSize={14}>
                I would like to
              </Typography>
              <Autocomplete
                fullWidth
                disablePortal
                id='combo-box-demo'
                options={top100Films}
                renderInput={(params) => (
                  <TextField {...params} label='Movie' />
                )}
              />
            </Box>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Title
              </InputLabel>
              <TextField
                fullWidth
                placeholder='Enter a title'
                InputProps={{ disableUnderline: true }}
                variant='outlined'
                sx={{
                  mt: 2,
                  mb: 2,
                  py: 1,
                  px: 2,
                  borderRadius: '10px',
                }}
                type='text'
              />
            </FormControl>{' '}
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Email address
              </InputLabel>
              <TextField
                fullWidth
                placeholder='Enter your email address'
                variant='outlined'
                InputProps={{ disableUnderline: true }}
                sx={{
                  mt: 2,
                  mb: 2,
                  py: 1,
                  px: 2,
                  borderRadius: '10px',
                }}
                type='email'
              />
            </FormControl>
          </Box>
          <Button
            fullWidth
            variant='contained'
            sx={{ p: '10px 14px', borderRadius: '8px', mt: '60%' }}
            onClick={() => setOpenPopUp(true)}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ReportBug;

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
];
