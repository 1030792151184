import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import Seat from './../assets/seat.svg';
import GraySeat from './../assets/GraySeat.svg';
import Wheel from './../assets/wheels.svg';
import SelectPayment from './SelectPayment';
import BottomDrawer from './BottomDrawer';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCharges } from '../services/payment';

const SelectSeatUI = ({ searchResults, selectedResult }: any) => {
  const [payment, setPayment] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState<string | null>(null);
  const navigate = useNavigate();

  // const availableSeats = selectedResult.available_seats;

  const handleSeatClick = (seatNo: string, isDisabled: boolean) => {
    if (!isDisabled) {
      setSelectedSeat(seatNo);
    }
  };

  const handleContinueClick = () => {
    if (selectedSeat) {
      navigate('/booking-details', {
        state: { searchResults, selectedResult, selectedSeat },
      });
    }
  };

  const isSeatTaken = (seatNo: string, i: number) => {
    // Check if the seat is in the selected_seats array from the backend response
    return (
      selectedResult.selected_seats.includes(seatNo) ||
      selectedResult?.selected_seats.includes((i! + 1).toString())
    );
  };

  console.log('Selected Results', selectedResult);

  const { data, isLoading } = useQuery({
    queryKey: ['GET_CHARGES'],
    queryFn: getCharges,
  });

  if (isLoading) {
    return (
      <Box
        display='grid'
        height='100vh'
        width='100%'
        sx={{ placeContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <BottomDrawer
        open={payment}
        onClose={() => setPayment(false)}
        secondDesign
        secondDesignTitle='Select payment method'
      >
        <SelectPayment activeBank={data?.data?.charges?.active_bank} />
      </BottomDrawer>
      {/* <BottomDrawer
        open={openBeneficiary}
        onClose={() => setOpenBeneficiary(false)}
        defaultDesign
        drawerHeight='80vh'
      >
        <NewBeneficiary
          searchResults={searchResults}
          selectedResult={selectedResult}
          selectedSeat={selectedSeat}
        />
      </BottomDrawer>  //removing Beneficiary in the process of booking */}
      <Box>
        <Box>
          <Box
            display='grid'
            gridTemplateColumns='1fr 1fr 1fr 1fr'
            borderRadius='12px'
            border='1px solid #ECECEC'
            my={2}
            py={2}
            gap='8px'
          >
            <Box display='grid' sx={{ placeItems: 'center' }}>
              <img src={Wheel} alt='Wheel' />
            </Box>
            {seats.slice(0, selectedResult.seats).map((seat, i) => {
              const isDisabled = isSeatTaken(seat.seatNo, i);
              return (
                <Box
                  key={seat.seatNo} // Added key for list items
                  display='grid'
                  sx={{
                    placeItems: 'center',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => handleSeatClick(seat.seatNo, isDisabled)}
                >
                  <img
                    src={isDisabled ? GraySeat : Seat}
                    alt='Seat'
                    style={{ opacity: isDisabled ? 0.5 : 1 }} // Add opacity for disabled seats
                  />
                  <Typography
                    textAlign='center'
                    color={
                      selectedSeat === seat.seatNo
                        ? '#98A2B3'
                        : isDisabled
                        ? '#d3d3d3'
                        : '#379E66'
                    }
                    fontSize='6.75px'
                    fontWeight={700}
                  >
                    Seat {seat.seatNo}
                  </Typography>
                  <Typography
                    textAlign='center'
                    color={isDisabled ? '#d3d3d3' : '#98A2B3'}
                    fontSize='6.75px'
                  >
                    {isDisabled ? 'Taken' : 'Available'}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box p={2}>
            <Button
              fullWidth
              variant='contained'
              sx={{ borderRadius: '8px', p: '12px 24px' }}
              onClick={handleContinueClick}
              disabled={!selectedSeat} // Disable button if no seat is selected
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SelectSeatUI;

const seats = [
  { seatNo: '1' },
  { seatNo: '2' },
  { seatNo: '3' },
  { seatNo: '4' },
  { seatNo: '5' },
  { seatNo: '6' },
  { seatNo: '7' },
  { seatNo: '8' },
  { seatNo: '9' },
  { seatNo: '10' },
  { seatNo: '11' },
  { seatNo: '12' },
  { seatNo: '13' },
  { seatNo: '14' },
];
