import React from 'react';
import CheckIcon from './../assets/check-circle.svg';
import CloseIcon from './../assets/close-icon.svg';
import { Box, Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const ReportPopUp = () => {
  let wrong = true;
  return (
    <Box padding={2}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Close fontSize='large' fontWeight={400} sx={{ cursor: 'pointer' }} />
      </Box>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <img src={wrong ? CloseIcon : CheckIcon} alt='icon' />
        <Typography fontSize={16} textAlign='center' mx={wrong ? 2 : 4}>
          {wrong
            ? 'Something went wrong... Please try again or Contact us at infomove9ja@gmail.com'
            : ' Thank you for reporting the bug. It’s very helpful for us!'}
        </Typography>
        <Button
          variant='contained'
          sx={{ p: '10px 14px', borderRadius: '8px' }}
          fullWidth
        >
          Close
        </Button>
      </div>
      <Typography
        display={wrong ? 'none' : 'block'}
        fontSize={16}
        textAlign='center'
        sx={{ textDecoration: 'underline', mt: 1 }}
      >
        Report another bug
      </Typography>
    </Box>
  );
};

export default ReportPopUp;
