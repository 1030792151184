import { Add, NavigateNext } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import gtbank from '../assets/gtbank.svg';
import { Button } from '@mui/material';

const Payouts = () => {
  const navigate = useNavigate();
  return (
    <Box height='100vh'>
      <Box p={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Payouts
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          {[1, 2, 3].map((_) => (
            <Box
              border='1px solid #F2F4F7'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              p='16px'
              mb='12px'
              borderRadius='8px'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <img src={gtbank} alt='' height={26} width={26} />
                <div>
                  <Typography fontWeight={600} fontSize={12}>
                    John Doe
                  </Typography>
                  <Typography fontSize={12}>.... 9872</Typography>
                </div>
              </div>
              <NavigateNext />
            </Box>
          ))}
        </Box>
        <Button
          sx={{ p: '12px 24px', color: 'white' }}
          variant='contained'
          fullWidth
        >
          <Add /> Add bank
        </Button>
      </Box>
    </Box>
  );
};

export default Payouts;
