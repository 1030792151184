import axios, { AxiosResponse } from 'axios';

const http = axios.create({
  baseURL: 'https://www.staging.move9ja.com/api/v1/',
});

http.interceptors.response.use(
  (res: AxiosResponse): AxiosResponse => {
    return res;
  },
  (error) => {
    if (error?.response?.data?.message === 'Invalid token Or Expired Token') {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

http.interceptors.request.use(
  async (req) => {
    req.headers.Authorization = localStorage.getItem('token') ?? '';
    return req;
  },
  async (error) => {
    Promise.reject(error);
  }
);

export default http;
