import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Car from '../assets/car.png';
import { ReUseAbleTripCards } from './TripOrderContent';
import TripCard from './TripCard';
import { useNavigate } from 'react-router-dom';
import CheckIcon from './../assets/check-circle.svg';
import ParkManagerDetails from './ParkManagerDetails';
import useSocket from '../hooks/useSocket';

import useGetUser from '../hooks/useGetUser';
import { generateRoomId } from '../utils/misc';
import { Rating } from '@mui/material';

const TripConcluded = ({ tripData }: { tripData?: any }) => {
  const { data } = useGetUser();
  const { trip_id, trip_status } = tripData;
  const navigate = useNavigate();

  const { emitCreateRoom, socket } = useSocket();

  // useEffect(() => {
  //   socket?.on('roomsCreated')
  // }, []);

  console.log('Trip status', trip_status);

  const handleChatClick = async () => {
    try {
      const roomId = generateRoomId(); // Generate the room ID
      await emitCreateRoom({
        roomId,
        participant_1: data?._id,
        participant_2: tripData?.park_manager?.id,
        trip_id,
      });

      // Navigate to the /messages/id route after a successful emit
      navigate(`/activities/${roomId}`);
    } catch (error) {
      console.error('Failed to create room:', error);
      // Optionally, handle error feedback for the user here
    }
  };

  return (
    <Box px={2}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        mt={3}
        mb={2}
      >
        <img
          src={CheckIcon}
          alt='check-icon'
          style={{
            height: 53.33,
            width: 53.33,
          }}
        />
        {trip_status === 'enroute' ? (
          <Typography>Trip is ongoing</Typography>
        ) : (
          <Typography mt={2} fontSize='20px' fontWeight={700}>
            Your trip has been concluded!!
          </Typography>
        )}
      </Box>
      <TripCard
        // onClick={() => navigate("/travel-detail")}
        from={tripData.from}
        to={tripData.to}
        parkbus_stop={tripData.parkbus_stop}
        parkname={tripData.parkname}
        stop_park={tripData.stop_park}
        trip_status='Completed'
      />
      <ReUseAbleTripCards title='Bus Details'>
        <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} p={1}>
          <img src={Car} alt='' height={74} width={74} />
          <Box fontSize={12}>
            <Typography fontSize={10} color='#98A2B3'>
              Vehicle
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.car_model}
            </Typography>
            <Typography fontSize={10} color='#98A2B3'>
              Driver
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.driver.fullname}
            </Typography>
          </Box>
          <Box display='flex' alignItems='flex-end' flexDirection='column'>
            <Typography fontSize={10} color='#98A2B3'>
              License Plate
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.license_plate}
            </Typography>
            <Typography fontSize={10} color='#98A2B3'>
              Phone
            </Typography>
            <Typography fontSize={12} fontWeight={600}>
              {tripData.driver.phoneNumber}
            </Typography>
          </Box>
        </Box>
      </ReUseAbleTripCards>
      <ParkManagerDetails
        tripStatus={trip_status}
        onChatClick={handleChatClick}
        fullname={tripData.park_manager.fullname}
      />

      <Box sx={{ display: 'grid', placeItems: 'center', my: 1, mt: 4 }}>
        <Typography fontSize='20px' fontWeight={600} mb={2}>
          Rate trip
        </Typography>
        <Rating value={3} size='large' sx={{ fontSize: '32px' }} />
      </Box>
    </Box>
  );
};
export default TripConcluded;
