import { Close } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Chip,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import FilterIcon from '../assets/filterIcon.svg';
import HelpIcon from '../assets/help.svg';

import Round from '../assets/round.png';
import bus from '../assets/bus.svg';
import car from '../assets/theCar.svg';
import Exclusive from '../assets/exclusive.svg';
import { ReactComponent as OrderArrow } from '../assets/order-arrow.svg';
import DashboardLayout from '../layouts/DashboardLayout';
import BottomDrawer from '../shared/BottomDrawer';
import { Filter } from '../shared/Filter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { search } from '../services/search';
import { format } from 'date-fns';
import { getDateSuffix } from '../shared/TheCalender';
import { formatCurrency } from '../utils/misc';

const SearchResult = () => {
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedResult, setSelectedResult] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const date = queryParams.get('date') || '';
  const type = queryParams.get('type') || '';
  const from = queryParams.get('from') || '';
  const to = queryParams.get('to') || '';
  const returnDate = queryParams.get('returnDate') || '';

  const query = useQuery({
    queryKey: ['searchResults', date, type, from, to, returnDate],
    queryFn: () =>
      search({
        date,
        type,
        from,
        to,
        return_date: returnDate,
      }),
    // staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const { data, isLoading } = query;

  if (isLoading) {
    return (
      <>
        <Backdrop
          open={true}
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.5)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <CircularProgress />
          <Typography fontWeight={600} fontSize='12px'>
            Checking for the best price for you
          </Typography>
          <Typography fontSize='12px'>Loading...</Typography>
        </Backdrop>
      </>
    );
  }
  const searchResults = data?.data?.search || [];
  console.log(searchResults);

  const formattedDate = (date: Date) => {
    const day = format(date, 'd');
    const month = format(date, 'MMM');
    return `${day}${getDateSuffix(Number(day))} ${month}`;
  };

  return (
    <DashboardLayout>
      <BottomDrawer
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        secondDesign
        secondDesignTitle='Filter'
      >
        <Filter searchResults={searchResults} selectedResult={selectedResult} />
      </BottomDrawer>
      <Box p={{ md: 2 }} mx={{ md: '14%' }}>
        <Box
          height='168px'
          bgcolor='#000'
          color='#FFF'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          px={2}
          pt={8}
        >
          <Box>
            <Typography
              fontSize='20px'
              fontWeight={700}
              sx={{ textTransform: 'capitalize' }}
            >
              {from} - {to}
            </Typography>
            <Typography fontSize='14px' fontWeight={400}>
              {searchResults.length} parks result (s)
            </Typography>
            <Typography fontSize='14px' fontWeight={400}>
              {formattedDate(new Date())}
            </Typography>
          </Box>
          <Box display='flex' alignItems='end' flexDirection='column'>
            <Close
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/home')}
            />
            <Typography height='25px' />
            <Typography
              fontSize='14px'
              fontWeight={400}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            >
              Modify search
            </Typography>
          </Box>
        </Box>
        <Box m={2}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography fontWeight={600}>Search results</Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <img
                src={FilterIcon}
                alt='filter-icon'
                onClick={() => setOpenFilter(true)}
                style={{ cursor: 'pointer' }}
              />
              <img
                src={HelpIcon}
                alt='help-icon'
                style={{ cursor: 'pointer' }}
              />
            </div>
          </Box>
          <Box>
            <Box
              display='grid'
              gridTemplateColumns={{ md: '1fr 1fr', xs: '1fr' }}
              gap='8px'
              mt='24px'
            >
              {searchResults.length === 0 && (
                <Box>
                  <Typography>No search results</Typography>
                </Box>
              )}
              {searchResults.map((result: any) => (
                <Box
                  key={result.vehicleId}
                  border='1px solid #E4E7EC'
                  borderRadius='8px'
                  p='12px'
                  height='162px'
                  width='100%'
                  bgcolor='#FFFFFF'
                  sx={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   navigate("/booking-details", { state: { result } })
                  // }
                  onClick={() => {
                    setSelectedResult(result);
                    setOpenFilter(true);
                  }}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <div>
                      <Typography
                        fontWeight={700}
                        color='#379E66'
                        fontSize={12}
                      >
                        {result.schedule.timeoftravel}
                      </Typography>
                      <Typography fontSize={12} my={1}>
                        {result.parkName}
                      </Typography>
                      <Typography fontSize={12} color='#98A2B3'>
                        {result.from}
                      </Typography>
                    </div>
                    <OrderArrow />
                    <div style={{ textAlign: 'right' }}>
                      {/* <Typography
                        fontWeight={700}
                        color="#379E66"
                        fontSize={12}
                      >
                        12:03pm
                      </Typography> */}
                      <Typography fontSize={12} my={1}>
                        {result.to} Park
                      </Typography>
                      <Typography fontSize={12} color='#98A2B3'>
                        {result.to}
                      </Typography>
                    </div>
                  </Box>
                  <div
                    style={{
                      gap: '8px',
                      alignItems: 'center',
                      display: 'flex',
                      marginTop: '12px',
                      marginBottom: '12px',
                    }}
                  >
                    <img src={result.car_type === 'Bus' ? bus : car} alt='' />
                    <Chip
                      sx={{
                        fontWeight: 600,
                      }}
                      label={`${
                        result.available_seats - result?.selected_seats?.length
                      } seats left`}
                      size='small'
                    />
                    <Chip
                      sx={{
                        fontWeight: 600,
                      }}
                      label={`${result.seats} seater`}
                      size='small'
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={12} fontWeight={700}>
                      NGN - {formatCurrency(result.price)}
                    </Typography>
                    <div
                      style={{
                        gap: '8px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {/* <Chip
                        sx={{
                          bgcolor: "#FFFAEB",
                          color: "#B54708",
                          fontWeight: 600,
                        }}
                        label="2km"
                        size="small"
                        icon={
                          <img
                            src={Flash}
                            alt="flash"
                            height="12px"
                            width="12px"
                          />
                        }
                      /> */}
                      {result.choice === 'Standard' ? (
                        <Chip
                          sx={{
                            bgcolor: '#EEF4FF',
                            color: '#3538CD',
                            fontWeight: 600,
                          }}
                          label='Standard'
                          size='small'
                          icon={
                            <img
                              src={Round}
                              alt='round'
                              height='12px'
                              width='12px'
                            />
                          }
                        />
                      ) : (
                        <Chip
                          sx={{
                            bgcolor: '#FDF2FA',
                            color: '#C11574',
                            fontWeight: 600,
                          }}
                          label='Exclusive'
                          size='small'
                          icon={
                            <img
                              src={Exclusive}
                              alt='exclusive'
                              height='12px'
                              width='12px'
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default SearchResult;

// {
//     "success": true,
//     "message": "Search result found",
//     "data": {
//         "search": [
//             {
//                 "vehicleId": "669fef2a01370dde5907b60f",
//                 "seats": 4,
//                 "available_seats": 4,
//                 "choice": "Exclusive",
//                 "car_model": "Venza ",
//                 "front_photo": "front_531033_1721757482902.png",
//                 "back_photo": "back_531033_1721757482902.png",
//                 "price": 25000,
//                 "car_type": "Car",
//                 "schedule": {
//                     "type": "recurring",
//                     "days": [
//                         "Monday",
//                         "Tuesday",
//                         "Friday",
//                         "Sunday",
//                         "Saturday"
//                     ],
//                     "timeoftravel": "8:00 AM"
//                 },
//                 "from": "Lagos",
//                 "to": "Ibadan",
//                 "available": true,
//                 "parkManagerId": "6698579018299ba5edf110d7",
//                 "parkName": "Salo Motor",
//                 "selected_seats": []
//             },
