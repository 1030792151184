import PackageIcon from '../assets/package.png';
import PhoneIcon from '../assets/phone.png';
import BeachIcon from '../assets/beach.png';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';
import useThemeColor from './../hooks/useThemeColor';
import { CardGiftcard, Close, ContentCopy } from '@mui/icons-material';
import { InputAdornment, TextField, useTheme } from '@mui/material';
import DashboardLayout from './../layouts/DashboardLayout';
import Move9jaCard from './../shared/Move9jaCard';

import TripCard from './../shared/TripCard';
import BusImage from './../assets/bus.png';
import TripOrderContent from '../shared/TripOrderContent';
import Booking from './Booking';
import TravelDetail from '../shared/TravelDetail';
import { useUser } from '../hooks/useUser';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AppBar, Backdrop, CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import Oneway from './Oneway';
import RoundTrip from './RoundTrip';
import Calendar from '../components/Move9jaCalendar';
import Booking3 from './Booking3';
import BottomDrawer from '../shared/BottomDrawer';
import TheCalender from '../shared/TheCalender';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';

import { getUserBooking } from '../services/booking';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const HomePage = () => {
  const user = useUser();
  const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [openBooking, setOpenBooking] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [openCalender, setOpenCalender] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const today = new Date();
  const formattedDate = format(today, 'yyyy-MM-dd');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const returnFormattedDate = format(selectedDate, 'yyyy-MM-dd');
  const [openDrop, setOpenDrop] = useState(false);

  const handleClick = () => {
    setOpenCalender(false);
    const tripType = value === 0 ? 'one-way' : 'round-trip';
    const queryParams = new URLSearchParams({
      date: formattedDate,
      from: locations.from.toLowerCase(),
      to: locations.to.toLowerCase(),
      type: tripType,
    });

    if (tripType === 'round-trip') {
      queryParams.append('returnDate', returnFormattedDate);
    }
    setOpenDrop(true);
    setTimeout(() => {
      setOpenDrop(false);
      navigate(`/search?${queryParams.toString()}`);
    }, 2000);
  };

  const [selectedField, setSelectedField] = useState<'from' | 'to' | null>(
    null
  ); // Track which field is active
  const [locations, setLocations] = useState({ from: '', to: '' }); // State to retain selected locations

  // Determine the current tab from the URL path
  const getCurrentTabIndex = () => {
    switch (location.pathname) {
      case '/home/oneway':
        return 0;
      case '/home/roundtrip':
        return 1;
      default:
        return 0;
    }
  };

  const [value, setValue] = React.useState<number>(getCurrentTabIndex);

  const title = selectedField === 'from' ? 'From where' : 'To where';

  useEffect(() => {
    // Update the tab index when the URL path changes
    setValue(getCurrentTabIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Update the URL based on the selected tab
    navigate(newValue === 0 ? '/home/oneway' : '/home/roundtrip');
  };
  const handleSelectLocation = (field: 'from' | 'to') => {
    setSelectedField(field);
    setActive(true);
  };

  const handleLocationSelect = (location: string) => {
    if (selectedField) {
      setLocations((prev) => ({ ...prev, [selectedField]: location }));
      setActive(false); // Return to RoundTrip view
      setSelectedField(null); // Reset the selected field
    }
  };

  useThemeColor('#000');
  const QUICK_LINS = [
    {
      img: PhoneIcon,
      title: 'Buy Airtime',
      onClick: () => {
        navigate('#');
      },
    },
    {
      img: BeachIcon,
      title: 'Group Booking',
      onClick: () => {
        navigate('#');
      },
    },
    {
      img: PackageIcon,
      title: 'Send Package',
      onClick: () => {},
    },
  ];
  const [tripData, setTripData] = React.useState(null);
  const { data: bookings, isLoading } = useQuery({
    queryKey: ['GET_BOOKINGS'],
    queryFn: getUserBooking,
  });

  const theme = useTheme();

  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        defaultDesign
        endText={
          <Typography fontSize='14px' color='#3E7EFF'>
            Share
          </Typography>
        }
      >
        <TripOrderContent tripData={tripData} />
      </BottomDrawer>
      <BottomDrawer
        open={openBooking}
        onClose={() => {
          setOpenBooking(false);
        }}
        defaultDesign
      >
        <Booking />
      </BottomDrawer>
      <BottomDrawer
        open={openDate}
        onClose={() => {
          setOpenDate(false);
        }}
        secondDesign
        secondDesignTitle='Select Date'
        endText={
          <Typography fontSize='14px' color='#3E7EFF'>
            Share
          </Typography>
        }
      >
        <TheCalender />
      </BottomDrawer>
      <BottomDrawer
        drawerHeight='100vh'
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      >
        <TravelDetail />
      </BottomDrawer>
      <Box p={{ md: 2 }} mx={{ md: '14%' }}>
        <Box
          display={{ xs: 'block', md: 'grid' }}
          sx={{ gridTemplateColumns: '59% 41%', gap: '4px' }}
        >
          <div>
            <Box
              px={2}
              py={4}
              color='#fff'
              bgcolor='#000'
              borderRadius={{ md: '12px' }}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                '::before': {
                  content: '""',
                  position: 'absolute',
                  top: 40,
                  right: 0,
                  width: '25%',
                  height: '50%',
                  backgroundImage: {
                    xs: `url(${BusImage})`,
                    md: `url()`,
                  },
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  zIndex: 1,
                },
                '::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '50%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  zIndex: 0,
                },
                zIndex: 0,
              }}
            >
              <Box
                sx={{ position: 'relative', zIndex: 2 }}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box>
                  <Typography
                    fontSize='12px'
                    fontWeight={400}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    Hi {user?.username} <span className='wave'> 👋</span>
                  </Typography>
                  <Typography fontSize='20px' fontWeight={700}>
                    Where are you going today?
                  </Typography>
                  <Typography
                    fontSize='12px'
                    fontWeight={600}
                    display={{ md: 'block', xs: 'none' }}
                    mt={3}
                  >
                    www.move9ja.com/ref=904moski
                    <ContentCopy
                      sx={{
                        height: 12,
                        width: 12,
                        color: 'green',
                        cursor: 'pointer',
                      }}
                      onClick={() => {}}
                    />
                  </Typography>
                </Box>
                <Box display={{ xs: 'none', md: 'block' }}>
                  <Box
                    component='img'
                    src={BusImage}
                    alt=''
                    sx={{
                      height: { md: '244px', xs: '80px' },
                      width: { md: '244px', xs: '80px' },
                    }}
                  />
                </Box>
              </Box>

              <TextField
                placeholder='Find a bus park'
                variant='standard'
                onClick={() => navigate('/booking')}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                inputProps={{
                  sx: {
                    '&::placeholder': {
                      padding: 1,
                      marginTop: 1.5,
                    },
                  },
                }}
                sx={{
                  position: 'relative',
                  zIndex: 2,
                  display: { md: 'none', xs: 'block' },
                  bgcolor: '#fff',
                  marginTop: 2,
                  padding: 1,
                  paddingX: 2,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  borderRadius: '10px',
                }}
              />
            </Box>
            <Box display={{ xs: 'none', md: 'block' }} pt={2}>
              <BottomDrawer
                open={openCalender}
                onClose={() => setOpenCalender(false)}
                secondDesign
                secondDesignTitle='Select Date'
                drawerHeight='80vh'
              >
                <TheCalender
                  selectedDate={selectedDate} // Pass selectedDate to TheCalender
                  setSelectedDate={setSelectedDate} // Pass setSelectedDate to TheCalender
                  onClick={handleClick}
                />
              </BottomDrawer>
              <Box sx={{ width: '100%' }}>
                {!active ? (
                  <Box sx={{ width: 'auto' }}>
                    <Box p={2}>
                      <Calendar onClick={() => setOpenCalender(true)} />
                    </Box>
                    <Box sx={{ borderRadius: '16px' }}>
                      <AppBar
                        position='static'
                        sx={{ borderRadius: '16px' }}
                        elevation={0}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          textColor='inherit'
                          variant='fullWidth'
                          TabIndicatorProps={{
                            style: {
                              display: 'none', // Hide the default indicator
                            },
                          }}
                          sx={{
                            bgcolor: '#F9F9F9',
                            color: '#667085',
                            border: '1px solid #E4E7EC',
                            borderRadius: '16px',
                            '& .MuiTab-root': {
                              textTransform: 'none',
                              fontWeight: 'bold',
                              borderRadius: '16px',
                              transition: 'all 0.3s ease',
                            },
                            '& .Mui-selected': {
                              border: '1px solid #E4E7EC',
                              bgcolor: '#fff',
                              color: '#344054',
                              borderRadius: '16px',
                            },
                          }}
                        >
                          <Tab
                            sx={{
                              textTransform: 'capitalize',
                              fontWeight: 600,
                            }}
                            label='One Way'
                            {...a11yProps(0)}
                          />
                          <Tab
                            sx={{
                              textTransform: 'capitalize',
                              fontWeight: 600,
                            }}
                            label='Round Trip'
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                    </Box>
                    <Box height='auto'>
                      <TabPanel value={value} index={0}>
                        <Oneway
                          onClickFrom={() => handleSelectLocation('from')}
                          onClickTo={() => handleSelectLocation('to')}
                          locations={locations} // Pass the selected locations as props
                          clickFindPark={() => setOpenCalender(true)}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <RoundTrip
                          onClickFrom={() => handleSelectLocation('from')}
                          onClickTo={() => handleSelectLocation('to')}
                          locations={locations} // Pass the selected locations as props
                          clickFindPark={() => setOpenCalender(true)}
                        />
                      </TabPanel>
                    </Box>
                  </Box>
                ) : (
                  <Booking3
                    onSelectLocation={handleLocationSelect}
                    title={title}
                  />
                )}
              </Box>
            </Box>
          </div>
          <Box>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                p: 1.5,
                my: { md: 0, xs: 2 },
                mx: 2,
                gap: '8px',
                height: '79px',
                borderRadius: 3,
                bgcolor: '#DA4733',
                color: 'white',
              }}
              onClick={() => {
                // setOpen(true);
              }}
            >
              <CardGiftcard />
              <Box>
                <Typography fontSize={14} fontWeight={600}>
                  Get N1,000 Free on every trip you request before 24th of March
                </Typography>
                <Typography fontSize={14} fontWeight={400}>
                  Try Now
                </Typography>
              </Box>
              <Close fontSize='medium' sx={{ height: 20, width: 20 }} />
            </Box> */}
            <Box mx={2}>
              <Move9jaCard title='Quick links'>
                <Box
                  display='grid'
                  gap={1}
                  gridTemplateColumns='1fr 1fr 1fr'
                  px={2}
                >
                  {QUICK_LINS.map(({ title, img, onClick }, i) => (
                    <Box
                      key={i}
                      sx={{ cursor: 'pointer' }}
                      width='100%'
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                      justifyContent='center'
                      border='1px solid #E4E7EC'
                      py={2}
                      borderRadius='8px'
                      my={1}
                      onClick={onClick}
                    >
                      <img src={img} alt='hello-img' width='64px' />
                      <Typography
                        textAlign='center'
                        fontSize='10px'
                        mt={2}
                        color={
                          theme?.palette?.mode === 'dark' ? '#fff' : '#344054'
                        }
                      >
                        {title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Move9jaCard>
              <Move9jaCard title='Bookings'>
                {isLoading && (
                  <Box
                    p={2}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <CircularProgress />
                  </Box>
                )}
                {bookings?.data?.bookings?.length === 0 && !isLoading && (
                  <Typography
                    sx={{
                      display: 'grid',
                      placeContent: 'center',
                      my: 5,
                      fontWeight: 500,
                      fontSize: '12px',
                    }}
                  >
                    No bookings available here yet
                  </Typography>
                )}
                {bookings?.data?.bookings?.map((trip: any, i: number) => (
                  <TripCard
                    {...trip}
                    key={i}
                    onClick={() => {
                      setTripData(trip);
                      setOpen(true);
                    }}
                  />
                ))}
                {isLoading && (
                  <Box sx={{ display: 'grid', placeContent: 'center' }}>
                    <CircularProgress
                      sx={{ display: 'grid', placeContent: 'center' }}
                    />
                  </Box>
                )}
              </Move9jaCard>
            </Box>
          </Box>
        </Box>
      </Box>
      <Backdrop
        open={openDrop}
        sx={{
          bgcolor: 'rgba(255, 255, 255, 0.5)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <CircularProgress />
        <Typography fontWeight={600} fontSize='12px'>
          Checking for the best price for you
        </Typography>
        <Typography fontSize='12px'>Loading...</Typography>
      </Backdrop>
    </DashboardLayout>
  );
};

export default HomePage;
