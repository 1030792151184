import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CheckCircleOutline } from "../../assets/check-circle.svg";
import move9jaLogo from "../../assets/move9ja.svg";
import move9jaBoy from "../../assets/move9jaBoy.png";
import { useLocation, useNavigate } from "react-router-dom";

const ProfileCompleted = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 80px"
        display={{ xs: "none", md: "flex" }}
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        <Button
          sx={{
            bgcolor: "#DA4733",
            width: "109px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
          }}
          variant="contained"
        >
          Register
        </Button>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "grid" },
          gridTemplateColumns: "40% 60%",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${move9jaBoy})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: { xs: "none", md: "grid" },
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box
          display="flex"
          height={{ md: "100vh" }}
          alignItems="center"
          flexDirection="column"
        >
          <Box
            width={{ xs: "100%", md: "510px" }}
            p="0px 24px"
            mt={{ md: "70px", xs: "40px" }}
          >
            <Box
              display="flex"
              mt={{ xs: 8, md: 0 }}
              mb={{ xs: 20, md: 0 }}
              alignItems="center"
              flexDirection="column"
            >
              <CheckCircleOutline
                fontSize="small"
                color="primary"
                style={{ height: 64, width: 64 }}
              />
              <Typography fontWeight={600} fontSize={20} my={3}>
                Sign up completed!
              </Typography>
              <Typography
                mx={{ md: 14, xs: 4 }}
                textAlign="center"
                fontWeight={500}
                fontSize={14}
                mb={4}
              >
                Welcome {location?.state?.username}, You’ve completed your
                registration
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "8px",
                p: "12px 24px",
                fontWeight: 400,
                mb: "12px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/booking");
              }}
              fullWidth
            >
              Book a ticket now
            </Button>
            <Button
              fullWidth
              sx={{
                color: "#000",
                bgcolor: "#F2F4F7",
                borderRadius: "8px",
                p: "12px 24px",
              }}
              variant="contained"
              onClick={() => {
                navigate("/home");
              }}
            >
              Go to Dashboard
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileCompleted;
