// Function to convert camelCase to snake_case
const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
};

// Function to convert an object's keys from camelCase to snake_case
export const convertKeysToSnakeCase = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = toSnakeCase(key);
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
};

export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith('0')) {
    return '234' + phoneNumber.slice(1);
  }
  return '234' + phoneNumber;
}

export function formatCurrency(amount: number): string {
  return amount.toLocaleString('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });
}

export const generateRoomId = (length = 8): string => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let roomId = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    roomId += characters[randomIndex];
  }
  return roomId;
};
