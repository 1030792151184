import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as OrderArrow } from './../assets/order-arrow.svg';
import { ReactComponent as OrderArrowWhite } from './../assets/order-arrow-white.svg';
// import { ReactComponent as BusIcon } from './../assets/bus-icon.svg';

// import { ReactComponent as ThunderIcon } from './../assets/thunder-bolt.svg';
// import { ReactComponent as CircularIcon } from './../assets/circular-icon.svg';

const TripCard = ({
  onClick,
  to,
  from,
  parkname,
  parkbus_stop,
  stop_park,
  trip_status,
  seats,
}: {
  onClick?: () => void;
  to?: string;
  from?: string;
  parkname?: string;
  parkbus_stop?: string;
  stop_park?: string;
  trip_status?: string;
  seats?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      my={1}
      mx={1}
      p={2}
      border='1px solid #E4E7EC'
      boxShadow='0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'
      borderRadius='8px'
      sx={{ cursor: 'pointer' }}
    >
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Typography
          width='35%'
          fontSize='12px'
          textAlign='left'
          fontWeight={600}
        >
          {parkname},<br />
          {parkbus_stop}
        </Typography>
        {theme?.palette?.mode === 'dark' ? <OrderArrowWhite /> : <OrderArrow />}
        <Typography
          width='35%'
          fontSize='12px'
          textAlign='right'
          fontWeight={600}
        >
          {stop_park}
        </Typography>
      </Box>
      <Box
        display='flex'
        mt={1}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography fontSize='12px' color='#98A2B3'>
          {from}
        </Typography>
        <Typography fontSize='12px' color='#98A2B3'>
          {to}
        </Typography>
      </Box>

      <Box mt={1} display='flex' gap={1} alignItems='center'>
        <TripStatusLabel trip_status={trip_status!} />
      </Box>
    </Box>
  );
};

const TripStatusLabel = ({ trip_status }: { trip_status: string }) => {
  // Convert trip_status to lower case for easier comparison
  const isNotStarted = trip_status.toLowerCase() === 'not started';

  return (
    <Typography
      fontWeight={600}
      bgcolor={isNotStarted ? '#FFF8E1' : '#ECFDF3'} // Yellow background for 'not started', else green
      padding='2px 8px 2px 6px'
      fontSize='12px'
      color={isNotStarted ? '#F57F17' : '#3538CD'} // Thicker yellow for 'not started', else blue
      borderRadius='16px'
      display='flex'
      gap={1}
      alignItems='center'
    >
      <Box
        height='8px'
        width='8px'
        borderRadius='50%'
        bgcolor={isNotStarted ? '#FBC02D' : '#379E66'} // Yellow dot for 'not started', else green
      />
      <span style={{ color: isNotStarted ? '#F57F17' : '#12B76A' }}>
        {trip_status}
      </span>
    </Typography>
  );
};

export default TripCard;
