import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { Typography, Box, styled } from '@mui/material';

const TimelineOppositeContentStyled = styled(TimelineOppositeContent)(
  ({ theme }) => ({
    flex: 0.2,
  })
);

const LocationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  fontSize: '12px',
}));

const TimeTypography = styled(Typography)(({ theme }) => ({
  color: '#4caf50',
  fontWeight: 'bold',
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SquareDot = styled(Box)(({ theme }) => ({
  width: '12px',
  height: '12px',
  backgroundColor: '#D9D9D9',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //margin: "0 auto",
  //   padding: "2px",
}));

export const calculateEndTime = (startTime: string, estimatedHours: number) => {
  const [time, period] = startTime?.split(' ');
  let [hours, minutes] = time?.split(':')?.map(Number);
  if (period === 'PM' && hours !== 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;

  const now = new Date();
  const startDateTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes
  );

  startDateTime.setHours(startDateTime.getHours() + estimatedHours);

  const endTime = startDateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return endTime;
};

export default function CustomTimeline({
  onClick,
  from,
  to,
  seat,
  startTime,
  busName,
  parkBusStop,
  parkName,
  carModel,
  estimatedHours,
  stopLocation,
}: {
  from?: any;
  to?: any;
  seat?: any;
  startTime?: any;
  busName?: any;
  parkBusStop?: any;
  parkName?: any;
  carModel?: any;
  estimatedHours?: any;
  stopLocation?: any;
  onClick?: () => void;
}) {
  const startTimed = startTime?.toString();
  const estimatedHoursd = estimatedHours || 6;
  const endTime = calculateEndTime(startTimed, estimatedHoursd);

  return (
    <Box onClick={onClick} sx={{ display: 'grid', ml: -24 }}>
      <Timeline>
        <TimelineItem>
          <TimelineOppositeContent color='text.secondary'>
            <LocationTypography>{from || 'Lagos'}</LocationTypography>
          </TimelineOppositeContent>
          <TimelineSeparator sx={{ mt: 2 }}>
            <SquareDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <TimeTypography sx={{ textTransform: 'uppercase' }}>
              {startTime || '6:45am'}
            </TimeTypography>
            <DescriptionTypography sx={{ fontSize: '12px' }}>
              {parkName} Park, <br /> {parkBusStop}
            </DescriptionTypography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <DescriptionTypography sx={{ fontSize: '12px', fontWeight: 600 }}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {busName || carModel}
              </span>{' '}
              <br />
              <span
                style={{ color: '#DA4733', fontSize: '12px', fontWeight: 600 }}
              >
                Seat {seat || '4B'}
              </span>
            </DescriptionTypography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color='text.secondary'>
            <LocationTypography>{to || 'Ilorin'}</LocationTypography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <SquareDot />
          </TimelineSeparator>
          <TimelineContent>
            <TimeTypography sx={{ textTransform: 'uppercase' }}>
              {endTime}
            </TimeTypography>
            <DescriptionTypography sx={{ fontSize: '12px' }}>
              {stopLocation || to} Park
            </DescriptionTypography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}
