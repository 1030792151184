import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const Sook = () => {
  // URL to your backend
  const SOCKET_URL = 'https://www.staging.move9ja.com';

  // Create the connection instance
  const socket = io(SOCKET_URL, {
    auth: {
      authorization: 'Bearer ' + localStorage.getItem('token'), // Pass the token in "Bearer" format
    },
    transports: ['websocket'], // Optional, ensure it's using WebSocket protocol
  });

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // Listen for connection
    socket.on('connect', () => {
      console.log('Connected to the server');
    });

    // Listen for messages from server
    socket.on('message', (message: any) => {
      console.log('message', message);
      //setMessages([...messages, message]);
    });
    socket.on('allNotification', (allNotification) => {
      console.log('allNotification', allNotification);
    });
    socket.on('userRooms', (userRooms) => {
      console.log('userRooms', userRooms);
    });
    // socket.on('message', (message) => {
    //     setMessages((prevMessages) => [...prevMessages, message]);
    // });

    // Handle connection error
    socket.on('connect_error', (err) => {
      console.log('Connection error: ', err.message);
    });

    // Clean up the effect when the component unmounts
    return () => {
      socket.off('connect');
      socket.off('message');
      socket.off('connect_error');
    };
  }, []);

  const sendMessage = () => {
    if (newMessage) {
      // Emit a message to the server
      socket.emit('sendMessage', newMessage);
      setNewMessage(''); // Clear input
    }
  };
  return <div>Sook</div>;
};

export default Sook;
