import http from "./http";

export interface IUser {
  firstName: string;
  lastName: string;
  username: string;
  dob: string;
  email: string;
  password: string;
  phone: string;
}
export const sendOtp = async (body: any) => {
  const res = http.post("/register/sendotp", body);
  return (await res).data;
};

export const verifyOtp = async (body: any) => {
  const res = http.post("/register/verifyotp", body);
  return (await res).data;
};

export const completeRegistration = async (body: IUser) => {
  const res = http.post("/register/createaccount", body);
  return (await res).data;
};

export const updateProfile = async (body: any) => {
  const res = http.put("/customer/profile/update-profile", body);
  return (await res).data;
};

export const getUserProfile = async () => {
  const res = http.get("/customer/profile/get-profile");
  return (await res).data;
};

export const loginCustomers = async (
  body: Pick<IUser, "email"> & {
    type: "email" | "phone";
  }
) => {
  const res = await http.post("/register/login", body);
  return res?.data;
};

export const loginCustomersPhone = async (
  body: Pick<IUser, "phone"> & {
    type: "phone" | "email";
  }
) => {
  const res = await http.post("/register/login", body);
  return res?.data;
};
