import {
  ArrowBack,
  CardGiftcard,
  LocalFireDepartment,
  NavigateNext,
} from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import useThemeColor from '../../hooks/useThemeColor';
import DashboardLayout from '../../layouts/DashboardLayout';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomDrawer from '../../shared/BottomDrawer';
import ReasonsForDecline from '../../shared/BottomDrawerContent/ReasonsForDecline';
import ReferralLinkContent from '../../shared/BottomDrawerContent/ReferalLinkContent';
import ProfileDetails from './ProfileDetails';
import useGetUser from '../../hooks/useGetUser';
import { useMutation } from '@tanstack/react-query';
import { updatePhoto } from '../../services/user_settings';
import toast from 'react-hot-toast';
import AppLoader from '../../components/Loader';

export const ProfileLayout = ({ children }: { children: ReactNode }) => {
  const PROFILELIST = [
    {
      title: 'Update profile',
      onClick: () => {
        navigate('/profile-details');
      },
    },
    {
      title: 'Medical Info',
      onClick: () => {
        navigate('/medical-info/health');
      },
    },
    {
      title: 'App Appearance',
      onClick: () => {
        navigate('/app-appearance');
      },
    },
    {
      title: 'Privacy Policy',
      onClick: () => {
        navigate('/privacy-policy');
      },
    },
    {
      title: 'Terms of Use',
      onClick: () => {
        navigate('/terms&conditions');
      },
    },
    {
      title: 'Contact Us',
      onClick: () => {
        navigate('/contact-us');
      },
    },
  ];
  const { data, isLoading } = useGetUser();

  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (file: string) => {
      return await updatePhoto(file);
    },
    onSuccess: (data) => {
      toast.success('Uploaded Successfully!');
      console.log(data, 'Pic has updated');
    },
    onError: (err) => {
      console.log('the error in ploading photo', err);
      toast.error(`${err}`);
    },
  });

  useThemeColor('#000');
  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <DashboardLayout>
      <BottomDrawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        defaultDesign
      >
        <ReferralLinkContent />
      </BottomDrawer>

      <Box
        display={{ xs: 'block', md: 'grid' }}
        gridTemplateColumns='1fr 1fr'
        mx={{
          md: '14%',
        }}
        gap={6}
      >
        <Box display={{ md: 'block', xs: 'none' }}>
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
            mb={1}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Profile
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: '#000000',
              height: '284px',
            }}
            mb={1}
          >
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'start',
                gap: 2,
              }}
              display={{ xs: 'flex', md: 'none' }}
              p={2}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack sx={{ color: '#FFFFFF' }} />
              </IconButton>
              <Typography fontWeight={700} fontSize={20} color='white'>
                Profile
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              mx={3}
              pt={{ md: 4 }}
            >
              <Stack
                bgcolor='#1570EF'
                color='#FFF'
                height={59}
                width={59}
                justifyContent='center'
                alignItems='center'
                borderRadius='100%'
                position='relative'
              >
                <Typography fontSize={23.6}> A</Typography>
                <Stack
                  position='absolute'
                  bottom={0}
                  right={0}
                  borderRadius='100%'
                  bgcolor='white'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  height={22}
                  width={22}
                >
                  <IconButton
                    onClick={() => {
                      navigate('/profile-details');
                    }}
                  >
                    <DriveFileRenameOutlineIcon
                      sx={{ color: 'green', height: 12, width: 12 }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Typography color='#FFF' fontSize={18} my={1}>
                {data?.firstName + ' ' + data?.lastName}
              </Typography>
              <Typography
                color='#FFF'
                fontSize={10}
                display='flex'
                gap={2}
                alignItems='center'
              >
                <p>{data?.email}</p>
                <Chip
                  size='small'
                  label='Verified'
                  sx={{ bgcolor: '#fff', color: '#667085', fontSize: '12px' }}
                  icon={<LocalFireDepartment sx={{ color: 'green' }} />}
                />
              </Typography>
            </Box>
            <Divider variant='fullWidth' color='gray' sx={{ my: 2 }} />
            <Box
              px={3}
              borderColor='#FFF'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box textAlign='center'>
                <Typography fontSize={10} color='#667085'>
                  Total Travelled
                </Typography>
                <Typography fontSize={12} color='#FFF'>
                  45
                </Typography>
              </Box>
              <Box textAlign='center'>
                <Typography fontSize={10} color='#667085'>
                  Distance travelled
                </Typography>
                <Typography fontSize={12} color='#FFF'>
                  7,894km
                </Typography>
              </Box>
              <Box textAlign='center'>
                <Typography fontSize={10} color='#667085'>
                  Total Referred
                </Typography>
                <Typography fontSize={12} color='#FFF'>
                  7
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box p={2}>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1.5,
                mb: 1,
                borderRadius: 3,
                bgcolor: '#9747FF',
              }}
              fullWidth
              variant='contained'
              onClick={() => setOpen(true)}
            >
              <Typography
                fontSize={13}
                fontWeight={500}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <CardGiftcard />
                NGN 500 gift for you
              </Typography>
              <NavigateNext fontSize='medium' sx={{ height: 20, width: 20 }} />
            </Button>
            {PROFILELIST.map((item) => (
              <Box key={item?.title}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: 2,
                    cursor: 'pointer',
                  }}
                  onClick={item.onClick}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <NavigateNext
                    fontSize='medium'
                    sx={{ height: 20, width: 20 }}
                  />
                </Box>
                <Divider />
              </Box>
            ))}
            <Button
              variant='contained'
              fullWidth
              sx={{
                mt: '16px',
                bgcolor: '#FFF5F4',
                color: '#DA4733',
                fontWeight: 400,
              }}
              onClick={async () => {
                console.log('LOG_OUT');
                await localStorage.clear();
                //  window.location.reload();
                window.location.href = '/login';
                console.log('Log Item TOKEN: ', localStorage.getItem('token'));
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Box width='100%'>{children}</Box>
      </Box>
    </DashboardLayout>
  );
};
