/**
 * Socket Provider
 */
import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';

export const SocketContext = React.createContext<{
  socket: Socket | null | undefined;
}>({
  socket: null,
});

/**
 * connectionConfig
 */
const connectionConfig = {
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 100,
  reconnectionAttempts: 100000,
  transports: ['websocket'],
};

/**
 * SocketProvider
 * @param {*} param0
 * @returns
 */

interface ProviderProps {
  children: ReactNode;
}

const BASE = 'https://www.staging.move9ja.com/';
// const BASE = 'wss://www.staging.move9ja.com/';

export const SocketProvider = ({ children }: ProviderProps) => {
  const token = localStorage.getItem('token');
  const env = BASE;
  const socket = useRef<Socket>();
  // console.log('token', token);

  useEffect(() => {
    if (token) {
      socket.current = socketIOClient(env, {
        ...connectionConfig,
        extraHeaders: {
          Authorization: `Bearer ${token}`,
        },
        auth: {
          token,
        },
      });
      socket.current?.on('connect', () => {
        console.log('socket provider connected');
      });

      socket.current?.on('disconnect', (msg) => {
        console.log('socket disconnect:', msg);
        socket.current = socketIOClient(env, {
          ...connectionConfig,
          extraHeaders: {
            Authorization: `Bearer ${token}`,
          },
          auth: {
            token,
          },
        });
      });
    }

    return () => {
      if (socket && socket.current) {
        socket?.current?.removeAllListeners();
        socket?.current?.close();
      }
    };
  }, [env, token]);

  return (
    <SocketContext.Provider value={{ socket: socket.current }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => useContext(SocketContext);
