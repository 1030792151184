import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AppBar, Backdrop, CircularProgress } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import EmergencyDetails from './EmergencyDetails';
import HealthDetails from './HealthDetails';
import { ProfileLayout } from '../ProfileLayout';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const MedicalInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [onEditHealth, setOnEditHealth] = useState(false);
  const [onEditEmergency, setOnEditEmergency] = useState(false);

  const getCurrentTabIndex = () => {
    switch (location.pathname) {
      case '/medical-info/health':
        return 0;
      case '/medical-info/emergency':
        return 1;
      default:
        return 0;
    }
  };

  const [value, setValue] = React.useState<number>(getCurrentTabIndex);

  useEffect(() => {
    setValue(getCurrentTabIndex());
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(
      newValue === 0 ? '/medical-info/health' : '/medical-info/emergency'
    );
    setOnEditEmergency(false);
    setOnEditHealth(false);
  };

  return (
    <ProfileLayout>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            m={2}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: 2,
              }}
            >
              <IconButton
                onClick={() => {
                  navigate('/profile');
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={800} fontSize={20}>
                Medical Info
              </Typography>
            </Box>
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                if (window.location.pathname === '/medical-info/health') {
                  setOnEditHealth(true);
                }
                if (window.location.pathname === '/medical-info/emergency') {
                  setOnEditEmergency(true);
                }
              }}
            >
              Edit
            </Typography>
          </Box>

          <AppBar
            position='static'
            sx={{ borderRadius: '16px', width: '90%', mx: 'auto' }}
            elevation={0}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor='inherit'
              variant='fullWidth'
              TabIndicatorProps={{
                style: {
                  display: 'none', // Hide the default indicator
                },
              }}
              sx={{
                color: '#667085',
                borderRadius: '16px',
                width: '80%',
                mx: 'auto',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '16px',
                  transition: 'all 0.3s ease',
                },
                '& .Mui-selected': {
                  border: '1px solid #E4E7EC',
                  bgcolor: '#fff',
                  color: '#344054',
                  borderRadius: '16px',
                },
              }}
            >
              <Tab
                sx={{ textTransform: 'capitalize', fontWeight: 600 }}
                label='Health Details'
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: 'capitalize', fontWeight: 600 }}
                label='Emergency Contact'
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>

          <Box height='auto'>
            <TabPanel value={value} index={0}>
              <HealthDetails
                onEditHealth={onEditHealth}
                setOnEditHealth={setOnEditHealth}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EmergencyDetails
                onEditEmergency={onEditEmergency}
                setOnEditEmergency={setOnEditEmergency}
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default MedicalInfo;
