import { Avatar, Box, Button, ButtonGroup } from '@mui/material';

import Move9jaLogo from '../assets/move9ja.svg';
import { FiberManualRecord } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

const NavBar = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path: string) => {
    navigate(path);
  };

  //#379E66
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      p='0px 32px'
      mx='5.6%'
    >
      <img src={Move9jaLogo} alt='' height='50.26px' width='162.75px' />
      <ButtonGroup>
        <Button
          variant='text'
          sx={{
            color: window.location.pathname === '/home' ? '#379E66' : '#344054',
            fontSize: '16px',
          }}
          startIcon={
            window.location.pathname === '/home' ? (
              <FiberManualRecord sx={{ color: 'green' }} fontSize='small' />
            ) : (
              <FiberManualRecord
                sx={{ color: 'transparent' }}
                fontSize='small'
              />
            )
          }
          onClick={() => handleButtonClick('/home')}
        >
          Home
        </Button>
        <Button
          variant='text'
          sx={{
            color:
              window.location.pathname === '/trips' ? '#379E66' : '#344054',
            fontSize: '16px',
          }}
          startIcon={
            window.location.pathname === '/trips' ? (
              <FiberManualRecord sx={{ color: 'green' }} fontSize='small' />
            ) : (
              <FiberManualRecord
                sx={{ color: 'transparent' }}
                fontSize='small'
              />
            )
          }
          onClick={() => handleButtonClick('/trips')}
        >
          Trips
        </Button>
        <Button
          variant='text'
          sx={{
            color:
              window.location.pathname === '/wallet' ? '#379E66' : '#344054',
            fontSize: '16px',
          }}
          startIcon={
            window.location.pathname === '/wallet' ? (
              <FiberManualRecord sx={{ color: 'green' }} fontSize='small' />
            ) : (
              <FiberManualRecord
                sx={{ color: 'transparent' }}
                fontSize='small'
              />
            )
          }
          onClick={() => handleButtonClick('/wallet')}
        >
          Wallet
        </Button>
        <Button
          variant='text'
          sx={{
            color:
              window.location.pathname === '/activities'
                ? '#379E66'
                : '#344054',
            fontSize: '16px',
          }}
          startIcon={
            window.location.pathname === '/activities' ? (
              <FiberManualRecord sx={{ color: 'green' }} fontSize='small' />
            ) : (
              <FiberManualRecord
                sx={{ color: 'transparent' }}
                fontSize='small'
              />
            )
          }
          onClick={() => handleButtonClick('/activities')}
        >
          Activities
        </Button>
      </ButtonGroup>
      <Box display='flex' alignItems='center'>
        <Avatar
          onClick={() => navigate('/profile')}
          sx={{ cursor: 'pointer', marginLeft: '15px' }}
        />
      </Box>
    </Box>
  );
};

export default NavBar;
