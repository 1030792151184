import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import DeliveryBox from '../assets/pack.svg';
import Bike from '../assets/bike.svg';
import { keyframes } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';

const slideIn = keyframes`
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(0);
  }
`;
interface FormValues {
  from: string;
  toWhere: string;
}

const RoundTrip = ({
  onClickFrom,
  onClickTo,
  locations,
  clickFindPark,
}: {
  onClickFrom: () => void; // Callback when 'from' field is clicked
  onClickTo: () => void; // Callback when 'to' field is clicked
  clickFindPark: () => void; // Callback when 'to' field is clicked
  locations: { from: string; to: string }; // The selected locations
}) => {
  const navigate = useNavigate();
  const [openT, setOpenT] = useState(false);
  const [open, setOpen] = useState(false);

  const handleToggle = (tab: string) => {
    setOpenT((prevOpen) => !prevOpen);
  };

  const { control, setValue } = useForm<FormValues>({
    defaultValues: {
      from: '',
      toWhere: '',
    },
  });

  return (
    <Box
      sx={{
        animation: `${slideIn} 0.5s ease-out`,
      }}
    >
      <Box display={{ xs: 'block', md: 'none' }}>
        <Box width='100%' component='form'>
          <Timeline
            sx={{
              width: '100%',
              p: 0,
              '.MuiTimelineOppositeContent-root': {
                display: 'none',
              },
              '.css-1w6r7oj-MuiTimelineDot-root': {
                m: 0,
              },
            }}
          >
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0 }} />
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#FFF',
                    border: '1px solid #ece4e7',
                    boxShadow: ' !important',
                    '& .MuiTimelineDot-root': {
                      boxShadow: ' !important',
                    },
                  }}
                >
                  <img
                    src={DeliveryBox}
                    alt='deli'
                    height='25px'
                    width='25px'
                    style={{ padding: 2 }}
                  />
                </TimelineDot>
                <TimelineConnector sx={{ border: '1px solid #98A2B3' }} />
              </TimelineSeparator>
              <TimelineContent>
                <Controller
                  control={control}
                  name='from'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      value={locations.from} // Display selected 'from' location
                      onClick={onClickFrom} // Trigger selection dialog on click
                      fullWidth
                      placeholder='From'
                      variant='standard'
                      sx={{
                        bgcolor: '#F9F9F9',
                        p: 1,
                        borderRadius: '8px',
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                />
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent sx={{ flex: 0 }} />
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#FFF',
                    border: '1px solid #ece4e7',
                    boxShadow: ' !important',
                    '& .MuiTimelineDot-root': {
                      boxShadow: ' !important',
                    },
                  }}
                >
                  <img
                    src={Bike}
                    alt='bike'
                    height='25px'
                    width='25px'
                    style={{ padding: 2 }}
                  />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Controller
                  control={control}
                  name='toWhere'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      value={locations.to} // Display selected 'from' location
                      onClick={onClickTo} // Trigger selection dialog on click
                      placeholder='To where'
                      variant='standard'
                      sx={{
                        bgcolor: '#F9F9F9',
                        p: 1,
                        borderRadius: '8px',
                        mt: -1,
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                />
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
        <Button
          variant='contained'
          fullWidth
          sx={{ fontWeight: 400, p: '10px 14px' }}
          onClick={clickFindPark}
        >
          Find a park
        </Button>
      </Box>
      <Box
        display={{ xs: 'none', md: 'flex' }}
        alignItems='center'
        justifyContent='space-between'
        gap={2}
      >
        <Controller
          control={control}
          name='from'
          render={({ field }) => (
            <TextField
              {...field}
              value={locations.from} // Display selected 'from' location
              onClick={onClickFrom} // Trigger selection dialog on click
              fullWidth
              placeholder='From'
              variant='standard'
              sx={{
                bgcolor: '#F9F9F9',
                p: 1,
                borderRadius: '8px',
              }}
              InputProps={{ disableUnderline: true }}
            />
          )}
        />
        <Controller
          control={control}
          name='toWhere'
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              value={locations.to} // Display selected 'from' location
              onClick={onClickTo} // Trigger selection dialog on click
              placeholder='To where'
              variant='standard'
              sx={{
                bgcolor: '#F9F9F9',
                p: 1,
                borderRadius: '8px',
              }}
              InputProps={{ disableUnderline: true }}
            />
          )}
        />
        <Button
          variant='contained'
          fullWidth
          sx={{ fontWeight: 400, p: '10px 14px' }}
          onClick={clickFindPark}
        >
          Find a park
        </Button>
      </Box>
      {/* <Move9jaCard title="History" endText="View all">
        {[1].map((_) => (
          <TripCard
            onClick={
              () => {}
              //setOpen(true)
            }
          />
        ))}
      </Move9jaCard> */}
    </Box>
  );
};

export default RoundTrip;
