import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';

const TopUp = () => {
  return (
    <>
      <Box p={3}>
        <Typography
          variant='body2'
          fontSize={22}
          textAlign='left'
          fontWeight={700}
          mb={2}
        >
          Top up
        </Typography>
        <FormControl variant='standard' fullWidth>
          <InputLabel shrink sx={{ fontWeight: 800, mb: 2 }}>
            Enter Deposit Amount
          </InputLabel>

          <TextField
            type='number'
            placeholder='#1,000'
            variant='standard'
            InputProps={{ disableUnderline: true }}
            sx={{
              bgcolor: '#F9F9F9',
              my: 2,
              borderRadius: '10px',
              borderStyle: 'none',
              py: 1,
              px: 2,
            }}
          />
        </FormControl>

        <Box mt={2}>
          <Button
            fullWidth
            disableElevation
            sx={{
              p: '12px 24px',
              borderRadius: 2.5,
            }}
            variant='contained'
            content='l'
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TopUp;
