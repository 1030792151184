import { Avatar, Box, Typography, useTheme } from '@mui/material';

const TravelerDetails = ({
  onClick,
  name = 'No Name test',
}: {
  onClick?: () => void;
  name?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      display='grid'
      gridTemplateColumns='1fr 1fr'
      py={1}
      borderBottom='1px solid #F2F4F7'
    >
      <Box display='flex' alignItems='center'>
        <Avatar sx={{ bgcolor: '#EE46BC', mr: 1 }}>EO</Avatar>
        <Box>
          <Typography
            fontWeight={600}
            fontSize='12px'
            color={theme.palette.mode === 'dark' ? 'white' : '#101828'}
          >
            {name}
          </Typography>
        </Box>
      </Box>

      <Typography
        display='flex'
        alignItems='center'
        fontWeight={600}
        fontSize='14px'
        sx={{ color: '#3E7EFF', cursor: 'pointer' }}
        onClick={onClick}
      >
        Change
      </Typography>
    </Box>
  );
};

export default TravelerDetails;
