import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Divider,
} from '@mui/material';
import DashboardLayout from './../layouts/DashboardLayout';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import TripCard from './../shared/TripCard';
import CustomTimeline from './../shared/TravelLocationTimeline';
import BottomDrawer from './../shared/BottomDrawer';
import TripOrderContent, {
  ReUseAbleTripCards,
} from './../shared/TripOrderContent';
import TripConcluded from '../shared/TripConcluded';
import { useQuery } from '@tanstack/react-query';
import { getUserTrips } from '../services/trips';
import { getTransactions } from '../services/payment';

const Trips = () => {
  const [open, setOpen] = React.useState(false);
  const [openConcludedTrip, setOpenConcludedTrip] = React.useState(false);
  const navigate = useNavigate();
  const [tripData, setTripData] = React.useState(null);

  const { data: trips, isLoading } = useQuery({
    queryKey: ['GET_TRIPS'],
    queryFn: getUserTrips,
  });

  const { data: transactions, isLoading: isTransactionsLoading } = useQuery({
    queryKey: ['GET_TRANSACTIONS'],
    queryFn: getTransactions,
  });

  const isPending = isTransactionsLoading || isLoading;

  if (isPending) {
    return (
      <Box
        display='flex'
        height='100vh'
        width='100%'
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <BottomDrawer
        defaultDesign
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        endText={
          <Typography fontSize='14px' color='#3E7EFF'>
            Share
          </Typography>
        }
      >
        <TripOrderContent />
      </BottomDrawer>
      <BottomDrawer
        defaultDesign
        open={openConcludedTrip}
        onClose={() => {
          setOpenConcludedTrip(false);
        }}
      >
        <TripConcluded tripData={tripData} />
      </BottomDrawer>
      <Box p={2} mx={{ md: '14%' }}>
        <Box display='flex' alignItems='center'>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            ml={1}
            fontSize='20px'
            fontWeight={700}
            onClick={() => {
              setOpen(true);
            }}
          >
            Trips
          </Typography>
        </Box>
        <Box
          display={{ xs: 'block', md: 'grid' }}
          sx={{ gridTemplateColumns: '45% 55%', gap: '12px' }}
        >
          <Box>
            <ReUseAbleTripCards
              title='Ongoing'
              bgcolor='#379E66'
              color='#fff'
              subtitle=''
            >
              {isPending && (
                <Box
                  p={2}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <CircularProgress />
                </Box>
              )}
              {trips?.data?.ongoing?.length === 0 && !isPending && (
                <Typography
                  sx={{
                    display: 'grid',
                    placeContent: 'center',
                    my: 5,
                    fontWeight: 500,
                    fontSize: '12px',
                  }}
                >
                  No ongoing trips yet
                </Typography>
              )}
              {trips?.data?.ongoing?.map(
                (selectedResult: any, i: number, arr: any) => {
                  return (
                    <>
                      <CustomTimeline
                        to={selectedResult?.to}
                        from={selectedResult?.from}
                        startTime={selectedResult?.timeoftravel}
                        seat={selectedResult?.seats}
                        parkBusStop={selectedResult?.parkbus_stop}
                        stopLocation={selectedResult?.stop_park}
                        parkName={selectedResult?.parkname}
                        onClick={() => {
                          console.log('Selected trip result', selectedResult);
                          setTripData(selectedResult);
                          setOpenConcludedTrip(true);
                        }}
                      />
                      {arr?.length - 1 !== i && <Divider />}
                    </>
                  );
                }
              )}
            </ReUseAbleTripCards>
          </Box>
          <Box>
            <ReUseAbleTripCards title='History'>
              {isPending && (
                <Box
                  p={2}
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <CircularProgress />
                </Box>
              )}
              {trips?.data?.completed?.length === 0 && !isPending && (
                <Typography
                  sx={{
                    display: 'grid',
                    placeContent: 'center',
                    my: 5,
                    fontWeight: 500,
                    fontSize: '12px',
                  }}
                >
                  No completed trips yet
                </Typography>
              )}
              {trips?.data?.completed.map((trip: any, i: number) => (
                <TripCard
                  trip_status='Completed'
                  {...trip}
                  key={i}
                  onClick={() => {
                    setTripData(trip);
                    setOpenConcludedTrip(true);
                  }}
                />
              ))}
            </ReUseAbleTripCards>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Trips;
