import { Close } from '@mui/icons-material';
import {
  Box,
  InputAdornment,
  keyframes,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';

import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';
import { SearchContext } from '../Context/searchContext';

const slideIn = keyframes`
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
`;

const Booking3 = ({
  onSelectLocation,
  title,
}: {
  onSelectLocation: (location: string) => void;
  title: string;
}) => {
  const { searchValue, handleSearchChange, setSearchValue } =
    useContext(SearchContext);

  // Reset search value when navigating away
  useEffect(() => {
    return () => {
      setSearchValue(''); // Reset searchValue on component unmount
    };
  }, [setSearchValue]);

  return (
    <Box
      sx={{
        animation: `${slideIn} 0.5s ease-out`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: 2,
        }}
        m={2}
      >
        <Typography fontWeight={800} fontSize={20}>
          {title}
        </Typography>
      </Box>
      <Box px={2}>
        <TextField
          placeholder='Search state or city'
          variant='standard'
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          value={searchValue}
          fullWidth
          inputProps={{
            sx: {
              '&::placeholder': {
                p: 1,
                mt: 1.5,
              },
            },
          }}
          sx={{
            bgcolor: '#F9F9F9',
            mt: 2,
            border: 'none',
            p: 1,
            px: 2,
            borderRadius: '8px',
          }}
        />
        <Typography fontWeight={600} fontSize='14px' color='#101828' my={2}>
          History
        </Typography>
        <Box>
          {location
            .filter((item) => {
              const lowerCaseSearchValue = searchValue.toLowerCase();
              const upperCaseSearchValue = searchValue.toUpperCase();
              return lowerCaseSearchValue === ''
                ? item
                : item.place.toLowerCase().includes(lowerCaseSearchValue) ||
                    item.place.toUpperCase().includes(upperCaseSearchValue);
            })
            .slice(0, 3)
            .map((location) => (
              <Box
                key={location.place}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                border='1px solid #E4E7EC'
                p='10px 14px'
                borderRadius='8px'
                mb={1}
                onClick={() => {
                  onSelectLocation(location.place);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Typography fontWeight={600} fontSize='12px'>
                  {location.place}
                </Typography>
                <Close />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Booking3;

const location = [
  { place: 'ABIA' },
  { place: 'ADAMAWA' },
  { place: 'AKWA IBOM' },
  { place: 'ANAMBRA' },
  { place: 'BAUCHI' },
  { place: 'BAYELSA' },
  { place: 'BENUE' },
  { place: 'BORNO' },
  { place: 'CROSS RIVER' },
  { place: 'DELTA' },
  { place: 'EBONYI' },
  { place: 'EDO' },
  { place: 'EKITI' },
  { place: 'ENUGU' },
  { place: 'GOMBE' },
  { place: 'IMO' },
  { place: 'JIGAWA' },
  { place: 'KADUNA' },
  { place: 'KANO' },
  { place: 'KATSINA' },
  { place: 'KEBBI' },
  { place: 'KOGI' },
  { place: 'KWARA' },
  { place: 'LAGOS' },
  { place: 'NASSARAWA' },
  { place: 'NIGER' },
  { place: 'OGUN' },
  { place: 'ONDO' },
  { place: 'OSUN' },
  { place: 'OYO' },
  { place: 'PLATEAU' },
  { place: 'RIVERS' },
  { place: 'SOKOTO' },
  { place: 'TARABA' },
  { place: 'YOBE' },
  { place: 'ZAMFARA' },
];
