import { Box, Chip, Typography } from '@mui/material';
import logoIcon from './../../assets/move9ja.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timId = setTimeout(() => {
      navigate('/login');
    }, 3000);

    return () => clearTimeout(timId);
  }, []);
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#379E66',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          gap: 3,
        }}
      >
        <img src={logoIcon} alt='logo-icon' />
        <Chip
          label='Manager'
          variant='filled'
          color='default'
          sx={{
            color: 'white',
            backgroundColor: 'black',
            fontWeight: '400',
            width: '68px',
            height: '28px',
          }}
        />
        <Typography
          variant='body2'
          sx={{ position: 'absolute', bottom: 0, mb: 6 }}
          fontWeight={600}
        >
          Version 1.01
        </Typography>
      </Box>
    </Box>
  );
};
