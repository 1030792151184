import { HelpOutlineRounded, SendOutlined } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Avatar, CircularProgress, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DashboardLayout from './../layouts/DashboardLayout';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BottomDrawer from './../shared/BottomDrawer';
import Details from './../shared/Details';
import useSocket from '../hooks/useSocket';
import useGetUser from '../hooks/useGetUser';
import { getDateAndTime } from '../utils/formatDate';
import { getUserProfile } from '../services/auth';
import { useQuery } from '@tanstack/react-query';
import { getAllRooms } from '../services/activities';

const Messages = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(''); // State for message input
  const [roomMessages, setRoomMessages] = useState<any>([]);
  const { id } = useParams();

  const { isLoading, data } = useGetUser();

  const { data: dtQu } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  const { data: rooms, isLoading: isRoomsLoading } = useQuery({
    queryKey: ['GET_ROOMS'],
    queryFn: getAllRooms,
  });

  console.log(
    'Rooms log',
    rooms?.data?.rooms?.find((x: any) => x?.room_id === id)?.participant
  );

  // Setup socket for joining the room and sending messages
  const { emitJoinRoom, emitSendMessage, socket } = useSocket({
    roomMessages: (data) => {
      console.log('Room messages', data?.messages);
      if (data?.messages) {
        // Update the roomMessages state with the received messages
        setRoomMessages((prevMessages: any) => [...data.messages]);
      }
    },
  });

  useEffect(() => {
    // Join the room if trip is active

    socket?.emit('joinRoom', { roomId: id });

    // Listen for incoming messages
    socket?.on('receiveMessage', (newMessage) => {
      // console.log('New messafe', newMessage);
      setRoomMessages((prevMessages: any) => [...prevMessages, newMessage]);
    });

    // socket?.on('roomMessages',)

    return () => {
      socket?.off('receiveMessage');
    };
  }, [socket, id]);

  const handleJoinRoom = () => {
    emitJoinRoom({ roomId: id! }, (response) => {});
  };

  const handleSendMessage = () => {
    if (message.trim()) {
      const messageObject = {
        event: 'sendMessage',
        roomId: id!,
        message,
      };
      emitSendMessage(messageObject, (response) => {});
      setMessage('');
      setRoomMessages([
        ...roomMessages,
        {
          event: 'sendMessage',
          roomId: id!,
          message,
          sender: [{ _id: data?._id }], // Set sender ID
          senderName: data?.name || 'Me', // Use current user's name
          timestamp: new Date().toISOString(), // Set the current timestamp
        },
      ]);
    }
  };

  useEffect(() => {
    handleJoinRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading || isRoomsLoading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DashboardLayout>
      <BottomDrawer open={open} onClose={() => setOpen(false)}>
        <Details />
      </BottomDrawer>
      <Box height='100vh' position='fixed' width='100%' mx={{ md: '14%' }}>
        <Box px={1} py={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={700} fontSize={20}>
                Message
              </Typography>
            </Box>
            <HelpOutlineRounded
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            />
          </Box>
          <Box p={2}>
            <Box display='flex' alignItems='center' gap={1} mb='6px'>
              <Avatar />
              <Typography fontSize={16} fontWeight={600}>
                {
                  rooms?.data?.rooms?.find((x: any) => x?.room_id === id)
                    ?.participant?.firstName
                }{' '}
                {
                  rooms?.data?.rooms?.find((x: any) => x?.room_id === id)
                    ?.participant?.lastName
                }
              </Typography>
              <Typography fontSize={12}>Online</Typography>
            </Box>
            <Typography
              fontSize={12}
              bgcolor='#F7FFEC'
              p='10px'
              borderRadius='4px'
              mb='24px'
            >
              You can only receive messages from a customer during a live trip.
            </Typography>

            <Box height='50vh' sx={{ overflowY: 'auto' }}>
              {/* Sample messages */}
              {roomMessages?.map((item: any, index: any) => {
                const checkIfMe = item?.sender[0] === data?._id;

                return (
                  <React.Fragment key={index}>
                    {checkIfMe ? (
                      // If it's the current user's message (receiver's message)
                      <Box
                        mb='12px'
                        display='flex'
                        alignItems='end'
                        justifyContent='flex-end'
                      >
                        <Box maxWidth='80%'>
                          <Typography fontSize={12}>
                            {getDateAndTime(item.timestamp) || 'Unknown Time'}
                          </Typography>
                          <Typography
                            fontSize={14}
                            color='white'
                            bgcolor='#379E66'
                            p='10px 14px'
                            borderRadius='8px 0px 8px 8px'
                          >
                            {item.message}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      // If it's someone else's message (sender's message)
                      <Box mb='12px'>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='space-between'
                          width='80%'
                        >
                          <Typography fontSize={14} fontWeight={600}>
                            {item.senderName || 'Unknown Sender'}
                          </Typography>
                          <Typography fontSize={12}>
                            {getDateAndTime(item?.timestamp)}
                          </Typography>
                        </Box>
                        <Typography
                          fontSize={14}
                          width='80%'
                          bgcolor='#F9FAFB'
                          border='1px solid #E4E7EC'
                          p='10px 14px'
                          borderRadius='0px 8px 8px 8px'
                        >
                          {item.message}
                        </Typography>
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
            </Box>

            {/* Message input */}
            <Box
              display='flex'
              position='fixed'
              bottom={{ xs: '99px', md: 0 }}
              width='90%'
            >
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                placeholder='Reply'
                value={message} // Bind the input to the state
                onChange={(e) => setMessage(e.target.value)} // Update the message state
                sx={{
                  p: '10px 0px 10px 14px',
                  borderRadius: '8px',
                  bgcolor: '#F9F9F9',
                  mr: '10px',
                  width: { xs: '100%', md: '70%' },
                }}
              />
              <Box
                height='44px'
                width='44px'
                bgcolor='#379E66'
                display='flex'
                justifyContent='center'
                alignItems='center'
                borderRadius='100%'
                p='10px'
                sx={{ cursor: 'pointer' }}
                onClick={handleSendMessage} // Trigger message send on click
              >
                <SendOutlined
                  sx={{ color: 'white', height: '24px', width: '24px' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Messages;
