import http from './http';

export const createBooking = async (body: any) => {
  const res = http.post('/booking/booking', body);
  return (await res).data;
};
export const getUserBooking = async () => {
  const res = await http.get('/customer/trips/get-user-bookings');
  return res.data;
};
