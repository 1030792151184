import { ArrowUpward, LastPage } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useState } from 'react';
import Move9jaCard from './Move9jaCard';
import CustomTimeline from './TravelLocationTimeline';
import TravelerDetails from './TravelerDetails';
import { Button, Divider, useTheme } from '@mui/material';
import BottomDrawer from './BottomDrawer';
import TripConcluded from './TripConcluded';
import ReasonsForDecline from './BottomDrawerContent/ReasonsForDecline';
import ParkManagerDetails from './ParkManagerDetails';
import ReportDriver from './ReportDriver';
import EmergencyServices from './EmergencyServices';

const TripOrderContent = ({ tripData }: any) => {
  const [open, setOpen] = React.useState(false);
  const [openDecline, setOpenDecline] = React.useState(false);
  const [openReportDriver, setOpenReportDriver] = React.useState(false);
  const [openEmergency, setOpenEmergency] = React.useState(false);
  console.log(tripData);
  const theme = useTheme();
  return (
    <>
      <Box px={2}>
        <Typography mt={2} fontSize='24px' fontWeight={700}>
          Order: {tripData.order_id}
        </Typography>
        <Typography my={1} fontSize='12px' color='#98A2B3' fontWeight={400}>
          Travel ID; {tripData.trip_id}
        </Typography>
        <Typography
          width='84px'
          fontWeight={600}
          px={2}
          sx={{
            bgcolor: '#F0F9FF',
            color: '#026AA2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '16px',
          }}
        >
          <ArrowUpward sx={{ color: '#0BA5EC', height: 12, width: 12 }} />
          <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
            {tripData.trip_status}
          </span>
        </Typography>

        <Box>
          <ReUseAbleTripCards
            title='Ongoing'
            bgcolor='#379E66'
            color='#fff'
            subtitle='6h 40m'
          >
            <CustomTimeline
              from={tripData.from}
              to={tripData.to}
              startTime={tripData.starttime}
              parkBusStop={tripData.parkbus_stop}
              stopLocation={tripData.stop_park}
              seat={tripData.traveller.selected_seat}
              busName={tripData.car_type}
              parkName={tripData.parkname}
            />
          </ReUseAbleTripCards>
        </Box>
        <ReUseAbleTripCards title='Bus Details'>
          <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap={1} p={1}>
            <img src={tripData.front_photo} alt='' height={74} width={74} />
            <Box fontSize={12}>
              <Typography fontSize={12}>Vehicle</Typography>
              <Typography fontSize={12} fontWeight={600}>
                {tripData.car_model}
              </Typography>
              <Typography fontSize={12}>Driver</Typography>
              <Typography fontSize={12} fontWeight={600}>
                Mr Adetola
              </Typography>
            </Box>
            <Box display='flex' alignItems='flex-end' flexDirection='column'>
              <Typography fontSize={12}>License Plate</Typography>
              <Typography fontSize={12} fontWeight={600}>
                {tripData.license_plate}
              </Typography>
              <Typography fontSize={12}>Phone</Typography>
              <Typography fontSize={12} fontWeight={600}>
                08107893792
              </Typography>
            </Box>
          </Box>
        </ReUseAbleTripCards>

        <Box mt={1}>
          {/* <CustomSlider text="Slide to complete your trip" /> */}
          {/* i will do a custom slider here */}
        </Box>
        {/* <Box mt={1}>
          <Button
            fullWidth
            sx={{
              borderRadius: '8px',
              bgcolor: '#F2F4F7',
              color: '#000',
              p: '14px',
            }}
            variant='contained'
            onClick={() => setOpen(true)}
          >
            Share live location
          </Button>
        </Box> */}
        <ParkManagerDetails fullname={tripData.park_manager.fullname} />
        <Move9jaCard title="Traveller's Details">
          <Box m={1}>
            <TravelerDetails name={tripData.traveller.fullname} />
            <Box p={1}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                mb={1}
              >
                <Typography fontSize='12px' color='#98A2B3'>
                  Phone Number
                </Typography>
                <Typography fontSize='12px'>
                  {tripData.traveller.phoneNumber}
                </Typography>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography fontSize='12px' color='#98A2B3'>
                  Email
                </Typography>
                <Typography fontSize='12px'>
                  {tripData.traveller.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Move9jaCard>
        <Typography
          color={theme.palette.mode === 'dark' ? 'white' : '#101828'}
          fontSize='12px'
          my={2}
        >
          Did something happen?
        </Typography>
        <Divider />
        <Button
          variant='outlined'
          fullWidth
          sx={{
            borderRadius: '8px',
            fontWeight: 600,
            p: '10px 14px',
            mt: '14px',
            color: 'red',
            border: '1px solid #E4E7EC',
          }}
          onClick={() => setOpenEmergency(true)}
        >
          Emergency
        </Button>
        <Button
          variant='outlined'
          fullWidth
          sx={{
            borderRadius: '8px',
            border: '1px solid #E4E7EC',
            fontWeight: 600,
            p: '10px 14px',
            mt: '14px',
            color: '#98A2B3',
          }}
          onClick={() => {
            setOpenReportDriver(true);
          }}
        >
          Report Driver
        </Button>
      </Box>
      <BottomDrawer
        defaultDesign
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <TripConcluded />
      </BottomDrawer>
      <BottomDrawer
        defaultDesign
        open={openReportDriver}
        drawerHeight='75vh'
        onClose={() => {
          setOpenReportDriver(false);
        }}
      >
        <ReportDriver />
      </BottomDrawer>
      <BottomDrawer
        defaultDesign
        open={openEmergency}
        onClose={() => {
          setOpenEmergency(false);
        }}
      >
        <EmergencyServices />
      </BottomDrawer>
      <BottomDrawer
        defaultDesign
        open={openDecline}
        onClose={() => {
          setOpenDecline(false);
        }}
      >
        <ReasonsForDecline />
      </BottomDrawer>
    </>
  );
};
export default TripOrderContent;

export const ReUseAbleTripCards = ({
  title,
  subtitle,
  bgcolor = '#F6F6F6',
  children,
  color = '#000',
  onClick,
}: {
  title: string;
  children: ReactNode;
  subtitle?: string;
  bgcolor?: string;
  color?: string;
  onClick?: () => void;
}) => {
  return (
    <Box
      border='1px solid #E4E7EC'
      borderRadius='8px'
      mt={3}
      overflow='hidden'
      onClick={onClick}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        bgcolor={bgcolor}
        color={color}
        p={1}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography fontSize='12px' fontWeight={400}>
          {title}
        </Typography>
        <Typography fontSize='12px' fontWeight={400}>
          {subtitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export const CustomSlider = ({ text }: { text: string }) => {
  const [position, setPosition] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isDragging) {
      const slider = e.currentTarget.getBoundingClientRect();
      const newPosition = e.clientX - slider.left;

      // Ensure the box stays within bounds
      if (newPosition >= 0 && newPosition <= slider.width - 44) {
        setPosition(newPosition);
      }
    }
  };

  return (
    <Box
      sx={{ width: '100%', m: '12px auto', position: 'relative' }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp} // Stop dragging when mouse is released
    >
      <Box
        bgcolor='#F2F4F7'
        display='flex'
        width='100%'
        alignItems='center'
        justifyContent='space-between'
        height='44px'
        position='relative'
        onMouseLeave={handleMouseUp} // Ensure dragging stops if mouse leaves the box
      >
        {/* The sliding box */}
        <Box
          height='44px'
          width='44px'
          bgcolor='#000'
          borderRadius='9.17px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='#FFF'
          sx={{
            position: 'absolute',
            left: position,
            cursor: 'grab',
            transition: 'left 0.1s ease-out',
            zIndex: 2,
          }}
          onMouseDown={handleMouseDown}
        >
          <LastPage sx={{ width: '35px', height: '35px' }} />
        </Box>

        {/* Centered text */}
        <Typography
          display='grid'
          fontWeight={600}
          fontSize='14px'
          sx={{
            placeItems: 'center',
            color: '#379E66',
            width: '100%',
            textAlign: 'center',
            zIndex: 1,
          }}
        >
          {text}
        </Typography>

        {/* Static box at the end */}
        <Box
          height='44px'
          width='44px'
          borderRadius='9.17px'
          bgcolor='#F6F6F6'
          border='1px solid #98A2B3'
          sx={{ position: 'relative', right: '0px' }}
        />
      </Box>
    </Box>
  );
};
