import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { keyframes } from '@emotion/react';
import { Check } from '@mui/icons-material';
import BottomDrawer from '../../shared/BottomDrawer';
import { useNavigate } from 'react-router-dom';
import { ProfileLayout } from './ProfileLayout';
import DeleteAccount from '../../shared/DeleteAccount';
import * as yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserProfile, updateProfile } from '../../services/auth';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetUser from '../../hooks/useGetUser';

import { handleMutationError } from '../../services/handler';
import toast from 'react-hot-toast';
import {
  deleteAccount,
  sendDeleteAccoutOtp,
} from '../../services/user_settings';
import { formatPhoneNumber } from '../../utils/misc';

const comeIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  // dob: yup.string().required('Dob is required'),
  username: yup.string().required('Username is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
});

const ProfileDetails = () => {
  // const data = useGetUser();
  const { data: profD, isLoading } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  console.log('Data', profD?.data?.profileDetails);

  const data = profD?.data?.profileDetails;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      return await updateProfile({
        ...data,
        phoneNumber: formatPhoneNumber(data?.phoneNumber),
      });
    },
    onSuccess: (data) => {
      console.log('data is updated as: ', data);
      toast.success('Saved Successfully!');
    },
    onError: (err) => {
      handleMutationError(err);
      console.log(err);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const sendDeleteOtpMutation = useMutation({
    mutationFn: sendDeleteAccoutOtp,
    onSuccess: (data) => {
      console.log('OTP sent successfully:', data);
      setOpenDelete(true);
    },
    onError: (err) => {
      console.log('Error sending OTP:', err);
    },
  });

  const handleSendOtpClick = () => {
    sendDeleteOtpMutation.mutate();
  };

  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = React.useState(false);
  if (isLoading) {
    return (
      <Box
        display='grid'
        height='100vh'
        width='100%'
        sx={{ placeContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ProfileLayout>
      <BottomDrawer
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        defaultDesign
      >
        <DeleteAccount />
      </BottomDrawer>
      <Box p={2} position='relative'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={700} fontSize={20}>
            Profile details
          </Typography>
        </Box>
        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
          <Typography mb={4} fontWeight={500}>
            Complete profile details correctly
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                First Name
              </InputLabel>
              <Controller
                name='firstName'
                control={control}
                defaultValue={data?.firstName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='standard'
                    disabled
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2.5,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors?.firstName}
                    helperText={
                      errors.firstName ? errors.firstName.message : ''
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800 }}>
                Last Name
              </InputLabel>
              <Controller
                name='lastName'
                control={control}
                defaultValue={data?.lastName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type='text'
                    variant='standard'
                    disabled
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      borderRadius: '10px',
                      borderStyle: 'none',
                      mt: 2.5,
                      py: 1,
                      px: 2,
                    }}
                    error={!!errors?.lastName}
                    helperText={errors.lastName ? errors.lastName.message : ''}
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Username
            </InputLabel>
            <Controller
              name='username'
              control={control}
              defaultValue={data?.username}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  disabled
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2.5,
                    mb: 2,
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                  }}
                  type='text'
                  error={!!errors?.username}
                  helperText={errors.username ? errors.username.message : ''}
                />
              )}
            />
          </FormControl>
          {/* <FormControl variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Date of Birthday <span>(optional)</span>
            </InputLabel>
            <Controller
              name='dob'
              control={control}
              // defaultValue={format(data?.dob, "yyyy-MM-dd")}
              render={({ field }) => (
                <TextField
                  {...field}
                  type='date'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2,
                    borderRadius: '10px',
                    borderStyle: 'none',
                    py: 1,
                    px: 2,
                  }}
                  error={!!errors?.dob}
                  helperText={errors.dob ? errors.dob.message : ''}
                />
              )}
            />
          </FormControl> */}
          <FormControl variant='standard' fullWidth sx={{ mt: 2 }}>
            <InputLabel shrink sx={{ fontWeight: 800 }}>
              Phone Number
            </InputLabel>
            <Controller
              name='phoneNumber'
              control={control}
              defaultValue={data?.phoneNumber?.toString().split('234')[1]}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position='start'>+234</InputAdornment>
                    ),
                  }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2.5,
                    mb: 2,
                    py: 1,
                    px: 2,
                    borderRadius: '10px',
                  }}
                  type='number'
                  error={!!errors?.phoneNumber}
                  helperText={
                    errors?.phoneNumber ? errors?.phoneNumber.message : ''
                  }
                />
              )}
            />
          </FormControl>
          <FormControl variant='standard' fullWidth>
            <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
              Email Address
            </InputLabel>
            <Controller
              name='email'
              control={control}
              defaultValue={data?.email || ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  type='email'
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2,
                    borderRadius: '10px',
                    borderStyle: 'none',
                    py: 1,
                    px: 2,
                  }}
                  error={!!errors?.email}
                  helperText={errors?.email ? errors?.email.message : ''}
                />
              )}
            />
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            sx={{ fontWeight: 400, p: 1, borderRadius: 2, my: 2 }}
            type='submit'
            disabled={!!mutation.isPending}
          >
            {mutation.isPending ? 'Updating...' : 'Continue'}
          </Button>
          <Button
            variant='contained'
            fullWidth
            component='button'
            sx={{
              fontWeight: 400,
              p: 1,
              borderRadius: 2,
              bgcolor: '#FFF5F4',
              color: 'red',
            }}
            onClick={handleSendOtpClick}
          >
            {sendDeleteOtpMutation.isPending ? (
              <CircularProgress
                sx={{
                  color: '#000',
                  display: 'grid',
                  placeContent: 'center',
                  height: '20px',
                  width: '20px',
                }}
              />
            ) : (
              'Delete account'
            )}
          </Button>
        </form>
      </Box>
    </ProfileLayout>
  );
};

export default ProfileDetails;
