import { ArrowBack, NavigateNext } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import faceBookIcon from './../../assets/facebook-icon.svg';
// import instagramIcon from "../assets/instagram-icon.svg";
import xIcon from './../../assets/x-icon.svg';
import tikTokIcon from './../../assets/tiktok-icon.svg';
import { useNavigate } from 'react-router-dom';
import { ProfileLayout } from './ProfileLayout';

const ContactUs = () => {
  const CONTACTLIST = [
    { title: 'Chat us Via WhatsApp', uri: 'https://wa.me/+2348143875931' },
    { title: 'Contact us via email', uri: 'mailto:info@move9ja.com' },
    // { title: 'Frequently asked questions' },
    { title: 'Suggest a feature', uri: 'https://wa.me/+2348143875931' },
    { title: 'Report a Bug', uri: 'https://wa.me/+2348143875931' },
  ];
  const navigate = useNavigate();
  return (
    <ProfileLayout>
      <Box p={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={3}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={700} fontSize={20}>
            Contact Us
          </Typography>
        </Box>
        {CONTACTLIST.map((item) => (
          <Box>
            <Box
              component='a'
              href={item?.uri}
              target='_blank'
              rel='noreferrer'
              sx={{ color: 'inherit' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 2,
                  cursor: 'pointer',
                }}
              >
                <Typography fontSize={13} fontWeight={500}>
                  {item.title}
                </Typography>
                <NavigateNext
                  fontSize='medium'
                  sx={{ height: 20, width: 20 }}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
        <Box display='flex' flexDirection='column' py={2} gap={2}>
          <Typography fontSize={13} fontWeight={700}>
            Follow us on social media
          </Typography>
          <Box
            display='flex'
            alignContent='center'
            justifyContent='normal'
            gap={1}
          >
            <a
              href='https://www.facebook.com/profile.php?id=61563478383509'
              rel='noreferrer'
              target='_blank'
            >
              <img
                style={{ cursor: 'pointer' }}
                src={faceBookIcon}
                alt=''
                height={47.66}
                width={47.66}
              />
            </a>
            <a
              href='https://x.com/OfficialMove9ja'
              rel='noreferrer'
              target='_blank'
            >
              <img
                style={{ cursor: 'pointer' }}
                src={xIcon}
                alt=''
                height={47.66}
                width={47.66}
              />
            </a>
            {/* <img
              style={{ cursor: 'pointer' }}
              src={tikTokIcon}
              alt=''
              height={47.66}
              width={47.66}
            /> */}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2,
              cursor: 'pointer',
            }}
            onClick={() => navigate('/about-us')}
          >
            <Typography fontSize={13} fontWeight={700}>
              About Us
            </Typography>
            <NavigateNext fontSize='medium' sx={{ height: 20, width: 20 }} />
          </Box>
          <Divider />
        </Box>
      </Box>
    </ProfileLayout>
  );
};

export default ContactUs;
