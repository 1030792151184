import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { AppBar, Backdrop, CircularProgress, useTheme } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import Oneway from './Oneway';
import RoundTrip from './RoundTrip';
import { ArrowBack } from '@mui/icons-material';
import Calendar from '../components/Move9jaCalendar';
import Booking2 from './Booking2';
import BottomDrawer from '../shared/BottomDrawer';
import TheCalender from '../shared/TheCalender';
import { format } from 'date-fns';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Booking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCalender, setOpenCalender] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const today = new Date();
  const formattedDate = format(today, 'yyyy-MM-dd');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const returnFormattedDate = format(selectedDate, 'yyyy-MM-dd');
  const [openDrop, setOpenDrop] = useState(false);

  const handleClick = () => {
    setOpenCalender(false);
    const tripType = value === 0 ? 'one-way' : 'round-trip';
    const queryParams = new URLSearchParams({
      date: formattedDate,
      from: locations.from.toLowerCase(),
      to: locations.to.toLowerCase(),
      type: tripType,
    });

    if (tripType === 'round-trip') {
      queryParams.append('returnDate', returnFormattedDate);
    }

    setOpenDrop(true);
    setTimeout(() => {
      setOpenDrop(false);
      navigate(`/search?${queryParams.toString()}`);
    }, 2000);
  };

  const [selectedField, setSelectedField] = useState<'from' | 'to' | null>(
    null
  ); // Track which field is active
  const [locations, setLocations] = useState({ from: '', to: '' }); // State to retain selected locations

  // Determine the current tab from the URL path
  const getCurrentTabIndex = () => {
    switch (location.pathname) {
      case '/booking/oneway':
        return 0;
      case '/booking/roundtrip':
        return 1;
      default:
        return 0;
    }
  };

  const [value, setValue] = React.useState<number>(getCurrentTabIndex);

  useEffect(() => {
    // Update the tab index when the URL path changes
    setValue(getCurrentTabIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // Update the URL based on the selected tab
    navigate(newValue === 0 ? '/booking/oneway' : '/booking/roundtrip');
  };
  const handleSelectLocation = (field: 'from' | 'to') => {
    setSelectedField(field);
    setActive(true);
  };

  const handleLocationSelect = (location: string) => {
    if (selectedField) {
      setLocations((prev) => ({ ...prev, [selectedField]: location }));
      setActive(false); // Return to RoundTrip view
      setSelectedField(null); // Reset the selected field
    }
  };

  const theme = useTheme();
  const title = selectedField === 'from' ? 'From where' : 'To where';

  return (
    <>
      <BottomDrawer
        open={openCalender}
        onClose={() => setOpenCalender(false)}
        secondDesign
        secondDesignTitle='Select Date'
        drawerHeight='80vh'
      >
        <TheCalender
          selectedDate={selectedDate} // Pass selectedDate to TheCalender
          setSelectedDate={setSelectedDate} // Pass setSelectedDate to TheCalender
          onClick={handleClick}
        />
      </BottomDrawer>
      <Box sx={{ width: '100%' }}>
        {!active ? (
          <Box sx={{ width: 'auto' }} mx={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                gap: 2,
              }}
              mt={2}
            >
              <IconButton
                onClick={() => {
                  navigate('/home');
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography fontWeight={800} fontSize={20}>
                From where
              </Typography>
            </Box>
            <Box p={2}>
              <Calendar onClick={() => setOpenCalender(true)} />
            </Box>
            <Box>
              <AppBar
                position='static'
                sx={{ borderRadius: '16px' }}
                elevation={0}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor='inherit'
                  variant='fullWidth'
                  TabIndicatorProps={{
                    style: {
                      display: 'none', // Hide the default indicator
                    },
                  }}
                  sx={{
                    bgcolor:
                      theme?.palette.mode === 'dark' ? '#000' : '#F9F9F9',
                    color: '#667085',
                    borderRadius: '16px',
                    border: '1px solid #E4E7EC',
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontWeight: 'bold',
                      borderRadius: '16px',
                      transition: 'all 0.3s ease',
                    },
                    '& .Mui-selected': {
                      border: '1px solid #E4E7EC',
                      bgcolor: '#fff',
                      color: '#344054',
                      borderRadius: '16px',
                    },
                    height: '36px',
                  }}
                >
                  <Tab
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      height: '36px',
                    }}
                    label='One Way'
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      textTransform: 'capitalize',
                      fontWeight: 600,
                      height: '36px',
                    }}
                    label='Round Trip'
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
            </Box>
            <Box
              height='auto'
              bgcolor={theme?.palette?.mode === 'dark' ? '#000' : '#fff'}
            >
              <TabPanel value={value} index={0}>
                <Oneway
                  onClickFrom={() => handleSelectLocation('from')}
                  onClickTo={() => handleSelectLocation('to')}
                  locations={locations} // Pass the selected locations as props
                  clickFindPark={() => setOpenCalender(true)}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <RoundTrip
                  onClickFrom={() => handleSelectLocation('from')}
                  onClickTo={() => handleSelectLocation('to')}
                  locations={locations} // Pass the selected locations as props
                  clickFindPark={() => setOpenCalender(true)}
                />
              </TabPanel>
            </Box>
          </Box>
        ) : (
          <Booking2 onSelectLocation={handleLocationSelect} title={title} />
        )}
      </Box>
      <Backdrop
        open={openDrop}
        sx={{
          bgcolor: 'rgba(255, 255, 255, 0.5)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <CircularProgress />
        <Typography fontWeight={600} fontSize='12px'>
          Checking for the best price for you
        </Typography>
        <Typography fontSize='12px'>Loading...</Typography>
      </Backdrop>
    </>
  );
};

export default Booking;
