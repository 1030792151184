import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import AddIcon from '@mui/icons-material/Add';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import GetVirtualCard from './../shared/GetVirtualCard';
import BottomDrawer from './../shared/BottomDrawer';
import { useState } from 'react';
import DashboardLayout from './../layouts/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import TopUp from './../shared/TopUp';
import Withdraw from '../shared/Withdraw';
import Move9jaCard from '../shared/Move9jaCard';
import WalletIcon from './../assets/wallet-icon.svg';
import { useQuery } from '@tanstack/react-query';
import { getTransactions } from '../services/payment';
import { formatDate } from '../utils/formatDate';
import { green } from '@mui/material/colors';

export const Wallet = () => {
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openTopUpWallet, setOpenTopUpWallet] = useState(false);
  const [transaction, setTransaction] = useState(false);

  const navigate = useNavigate();
  const walletCard = [
    {
      icon: <AddIcon sx={{ color: '#379E66' }} />,
      title: 'Top Up Wallet',
      onClick: () => {
        setOpenTopUpWallet(true);
      },
    },
    {
      icon: <ArrowOutwardIcon sx={{ color: '#379E66' }} />,
      title: 'Withdraw',
      onClick: () => {
        setOpenWithdraw(true);
      },
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ['GET_TRANSACTIONS'],
    queryFn: getTransactions,
  });

  return (
    <DashboardLayout>
      <BottomDrawer
        secondDesign
        secondDesignTitle='Withdraw'
        drawerHeight='40vh'
        open={openWithdraw}
        onClose={() => {
          setOpenWithdraw(false);
        }}
      >
        <Withdraw />
      </BottomDrawer>
      <BottomDrawer
        secondDesign
        secondDesignTitle='Top Up'
        drawerHeight='40vh'
        open={openTopUpWallet}
        onClose={() => {
          setOpenTopUpWallet(false);
        }}
      >
        <TopUp />
      </BottomDrawer>
      <Box p={2} mx={{ md: '14%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 2,
          }}
          mb={1}
        >
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography fontWeight={800} fontSize={20}>
            Transactions
          </Typography>
        </Box>

        <Box
          display={{ xs: 'block', md: 'grid' }}
          sx={{ gridTemplateColumns: '45% 55%', gap: '12px' }}
        >
          <Move9jaCard marginTop={0} title='Transaction activities'>
            {data?.data?.transactions?.length === 0 && !isLoading && (
              <Typography
                sx={{
                  display: 'grid',
                  placeContent: 'center',
                  my: 5,
                  fontWeight: 500,
                  fontSize: '12px',
                }}
              >
                No transactions available here yet
              </Typography>
            )}
            {isLoading && (
              <Box
                p={2}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <CircularProgress />
              </Box>
            )}
            <Box p={1}>
              {data?.data?.transactions?.map(
                ({ amount, type, status, transactionDate }: any) => (
                  <Box
                    p={1}
                    border='1px solid #E4E7EC'
                    borderRadius='8px'
                    overflow='hidden'
                    my={1}
                    key={transactionDate}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      mb={1}
                    >
                      <Box display='flex' alignItems='center' gap={1}>
                        <div
                          style={{
                            backgroundColor: '#DA4733',
                            borderRadius: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 24,
                            width: 24,
                          }}
                        >
                          <ArrowOutwardIcon
                            sx={{ color: 'white', height: 18, width: 18 }}
                          />
                        </div>
                        <Typography fontSize={12} fontWeight={600}>
                          {type}
                        </Typography>
                      </Box>
                      <Typography fontSize={12} fontWeight={600}>
                        NGN {amount.toLocaleString()}
                      </Typography>
                    </Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography color='#98A2B3' fontSize={12}>
                        {formatDate(transactionDate)}
                      </Typography>
                      <Chip
                        label={status}
                        sx={{
                          textTransform: 'capitalize',
                          bgcolor: green[200],
                          color: green[900],
                        }}
                      />
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </Move9jaCard>
        </Box>
      </Box>
    </DashboardLayout>
  );
};
