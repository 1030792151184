import {
  ArrowBack,
  CardGiftcard,
  LocalFireDepartment,
  NavigateNext,
} from '@mui/icons-material';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import useThemeColor from '../../hooks/useThemeColor';
import { useNavigate } from 'react-router-dom';

import React, { useRef } from 'react';

import BottomDrawer from '../../shared/BottomDrawer';
import ReferralLinkContent from '../../shared/BottomDrawerContent/ReferalLinkContent';
import useGetUser from '../../hooks/useGetUser';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { updatePhoto } from '../../services/user_settings';
import toast from 'react-hot-toast';
import { ProfileLayout } from './ProfileLayout';
import { getUserProfile } from '../../services/auth';
import AppLoader from '../../components/Loader';

export const Profile = () => {
  const PROFILELIST = [
    {
      title: 'Update profile',
      onClick: () => {
        navigate('/profile-details');
      },
    },
    {
      title: 'Medical Info',
      onClick: () => {
        navigate('/medical-info');
      },
    },
    {
      title: 'App Appearance',
      onClick: () => {
        navigate('/app-appearance');
      },
    },
    {
      title: 'Privacy Policy',
      onClick: () => {
        navigate('/privacy-policy');
      },
    },
    {
      title: 'Terms of Use',
      onClick: () => {
        navigate('/terms&conditions');
      },
    },

    {
      title: 'Contact Us',
      onClick: () => {
        navigate('/contact-us');
      },
    },
  ];
  const navigate = useNavigate();

  const { data, isLoading } = useGetUser();

  const { data: tripDet } = useQuery({
    queryKey: ['GET_USER_PROFILE'],
    queryFn: getUserProfile,
  });

  const queryClient = new QueryClient();

  const mutation = useMutation({
    mutationFn: async (file: any) => {
      return await updatePhoto(file);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['GET_USER_PROFILE'] });
      toast.success('Uploaded Successfully!');
    },
    onError: (err) => {
      console.log('the error in ploading photo', err);
      toast.error(`${err}`);
    },
  });

  const ref = useRef<any>();

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    mutation.mutate(file);
  };

  useThemeColor('#000');
  const [open, setOpen] = React.useState(false);
  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <ProfileLayout>
      <>
        <BottomDrawer
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          defaultDesign
        >
          <ReferralLinkContent />
        </BottomDrawer>

        <Box display={{ md: 'none', xs: 'block' }}>
          <Box
            sx={{
              display: { md: 'flex', xs: 'none' },
              alignItems: 'center',
              justifyContent: 'start',
              gap: 2,
            }}
            mb={1}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={700} fontSize={20}>
              Profile
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: '#000000',
              height: '284px',
            }}
            mb={1}
          >
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'start',
                gap: 2,
              }}
              display={{ xs: 'flex', md: 'none' }}
              p={2}
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack sx={{ color: '#FFFFFF' }} />
              </IconButton>
              <Typography fontWeight={700} fontSize={20} color='white'>
                Profile
              </Typography>
            </Box>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              mx={3}
              pt={{ md: 4 }}
            >
              <input
                ref={ref}
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id='upload-file'
              />
              <Stack
                bgcolor={
                  tripDet?.data?.profileDetails?.image ? '#fff' : '#1570EF'
                }
                color='#FFF'
                height={59}
                width={59}
                justifyContent='center'
                alignItems='center'
                borderRadius='100%'
                position='relative'
              >
                {tripDet?.data?.profileDetails?.image ? (
                  <img
                    src={tripDet?.data?.profileDetails?.image}
                    alt='profile-img'
                    width='45px'
                    style={{ borderRadius: '50%' }}
                  />
                ) : (
                  <Typography fontSize={23.6}> {data?.username[0]}</Typography>
                )}
                <Stack
                  position='absolute'
                  bottom={0}
                  right={0}
                  borderRadius='100%'
                  bgcolor='white'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  height={22}
                  width={22}
                >
                  <IconButton
                    onClick={() => {
                      ref!.current.click();
                    }}
                  >
                    <DriveFileRenameOutlineIcon
                      sx={{ color: 'green', height: 12, width: 12 }}
                    />
                  </IconButton>
                </Stack>
              </Stack>
              <Typography color='#FFF' fontSize={18} my={1}>
                {data?.firstName + ' ' + data?.lastName}
              </Typography>
              <Typography
                color='#FFF'
                fontSize={10}
                display='flex'
                gap={2}
                alignItems='center'
              >
                <p>{data?.email}</p>
                <Chip
                  size='small'
                  label='Verified'
                  sx={{ bgcolor: '#fff', color: '#667085', fontSize: '12px' }}
                  icon={<LocalFireDepartment sx={{ color: 'green' }} />}
                />
              </Typography>
            </Box>
            <Divider variant='fullWidth' color='gray' sx={{ my: 2 }} />
            <Box
              px={3}
              borderColor='#FFF'
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box textAlign='center'>
                <Typography fontSize={10} color='#667085'>
                  Total Travelled
                </Typography>
                <Typography fontSize={12} color='#FFF'>
                  {tripDet?.data?.completedTripCount}
                </Typography>
              </Box>

              <Box textAlign='center'>
                <Typography fontSize={10} color='#667085'>
                  Total Referred
                </Typography>
                <Typography fontSize={12} color='#FFF'>
                  7
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box p={2}>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1.5,
                mb: 1,
                borderRadius: 3,
                bgcolor: '#9747FF',
              }}
              fullWidth
              variant='contained'
              onClick={() => setOpen(true)}
            >
              <Typography
                fontSize={13}
                fontWeight={500}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <CardGiftcard />
                NGN 500 gift for you
              </Typography>
              <NavigateNext fontSize='medium' sx={{ height: 20, width: 20 }} />
            </Button>
            {PROFILELIST.map((item) => (
              <Box key={item?.title}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: 2,
                    cursor: 'pointer',
                  }}
                  onClick={item.onClick}
                >
                  <Typography fontSize={14} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <NavigateNext
                    fontSize='medium'
                    sx={{ height: 20, width: 20 }}
                  />
                </Box>
                <Divider />
              </Box>
            ))}
            <Button
              variant='contained'
              fullWidth
              sx={{
                mt: '16px',
                bgcolor: '#FFF5F4',
                color: '#DA4733',
                fontWeight: 400,
              }}
              onClick={() => {
                localStorage.clear();
                //  window.location.reload();
                window.location.href = '/login';
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </>
    </ProfileLayout>
  );
};
